footer {
    /* padding-top: 30px; */
    text-align: start;
    color: var(--backgroundColor);
}

/* footer .footLogo img:first-child {
    margin-inline-end: 30px;
    width: 160px;
    object-fit: contain;
} */

footer .footLogo img {
    width: 200px;
    object-fit: contain;
    margin-right: auto;
}

footer h2 {
    font-weight: bold;
    margin-bottom: 27px;
}

footer ul {
    padding-inline-start: 0;
    margin-bottom: 0;
    list-style-type: none;
}

footer ul li {
    /* padding-bottom: 5px; */
}

footer ul li a {
    color: var(--backgroundColor);
    position: relative;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    font-weight: normal;
}

footer .footLinks ul {
    display: flex;
    flex-wrap: wrap;
}

footer .footLinks ul li:not(:last-child) {
    padding-right: 25px;
    padding-bottom: 20px;
}

footer .footSocial .worldIcon {
    font-size: 30px;
}

footer .footSocial img {
    width: 25px;
    height: 25px;
}

footer ul li a:hover {
    text-decoration: none;
    color: var(--primColor);
}

footer .footBorder {
    border-top: 1px solid #4e5a68;
}

footer .fixedFoot {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 30px 0;
}

footer .fixedFoot .rightnavLinks .languageSwitch img {
    margin-right: 5px;
}

footer .fixedFoot .rightnavLinks .languageSwitch span {
    display: none;
}

footer .footAddres {
    display: grid;
    grid-template-columns: 190px 190px;
    grid-gap: 80px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 40px;
}

footer .footAddres .addresscard address {
    font-size: 16px;
    font-weight: 400;
    color: #69717b;
}

footer .footAddres .addresscard h4 {
    font-size: 16px;
    font-weight: 400;
}

footer .legal_mentionsStyl #footer_legal_mentions {
    font-size: 12px;
}

footer .legal_mentionsStyl {
    margin-top: 67px;
}

footer ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--textColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

footer ul li a:hover:after {
    width: 100%;
    left: 0;
}

footer .footLinks ul:not(:last-child) {
    margin-inline-end: 75px;
}

footer .footMisc .footSocial li:not(:last-child) {
    margin-inline-end: 30px;
}

footer .footMisc .footSubscribe form {
    background: #3a658e;
    border-radius: 5px;
    height: auto;
    flex-wrap: nowrap;
}

footer .footMisc .footSubscribe form {
    margin-bottom: 5px;
}

footer .footMisc .footSubscribe form .customInput {
    margin-bottom: 0;
}

footer .footMisc .footSubscribe form .customInput {
    background: inherit;
    border: none;
    color: var(--textColor);
    height: inherit;
}

.footSubscribe .MuiFormLabel-root {
    color: var(--textColor);
}

footer .footMisc .footSubscribe form .subsInp {
    align-self: stretch;
}

footer .footMisc .footSubscribe form button {
    height: auto;
    align-self: stretch;
}

footer .footMisc .footSubscribe form .MuiFormHelperText-root.Mui-error {
    color: #000000;
    background: #ff616163;
    margin: 0;
    padding: 3px 10px;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:before {
    border-bottom: transparent;
}

footer .userGetForm .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footMisc .footSubscribe form input:focus {
    box-shadow: none;
    border: none;
}

footer .footMisc .footSubscribe form input::placeholder {
    color: var(--backgroundColor);
}

footer .footMisc .footSubscribe form button {
    color: var(--backgroundColor);
    display: flex;
}

footer .footMisc .footSubscribe small {
    color: var(--textColor);
}

footer .rightnavLinks span,
footer .rightnavLinks a {
    font-size: 15px;
    font-weight: 400;
    color: #8d939a;
}

footer .rightnavLinks {
    margin: 0;
    text-align: right;
    margin-top: 0px;
    display: flex;
    align-items: center;
}

footer .rightnavLinks .footNav {
    display: flex;
}

footer .poweredBy {
    color: var(--backgroundColor);
    margin: 15px 0 0 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .hashTag {
    color: var(--textColor);
    margin: 0 0 15px 0;
    font-size: 14px;
    text-align: right;
    font-weight: normal;
}

footer .footCnt {
    /* border-top: 1px solid #e7e7e7; */
    /* padding-top: 60px; */
    background-color: var(--textColor);
}

footer .footLogo {
    margin-bottom: 40px;
}

footer
    .footgridSplt
    .customerGetintoForm
    .userGetForm
    .subsInp
    .customInput
    .MuiFilledInput-underline:before {
    border-bottom: 1px solid #3a4450;
}

footer .footgridSplt .customerGetintoForm .userGetForm .subsInp .customInput .MuiFilledInput-input {
    padding: 30px 0px 10px;
    color: var(--backgroundColor);
}

footer .SubBtn {
    max-width: 113px;
}

footer
    .footgridSplt
    .customerGetintoForm
    .userGetForm
    .subsInp
    .customInput
    .MuiFilledInput-input::placeholder {
    color: #69717b;
}

footer .footgridSplt .customerGetintoForm .userGetForm .subsInp .customInput .MuiFormLabel-root {
    color: var(--backgroundColor);
    font-size: 13px;
    transform: translateX(0px);
}

footer
    .footgridSplt
    .customerGetintoForm
    .userGetForm
    .subsInp
    .customInput
    .MuiFormLabel-root
    footer
    .footgridSplt
    .customerGetintoForm
    .userGetForm
    .subsInp
    .customInput,
footer .footgridSplt .customerGetintoForm .userGetForm .subsInp {
    color: var(--backgroundColor);
}

footer .footgridSplt .customerGetintoForm .userGetForm {
    margin-top: 22px;
}

footer .footgridSplt .customerGetintoForm .getinTouchPara {
    font-size: 15px;
    font-weight: 400;
    color: #69717b;
}

footer .footgridSplt .customerGetintoForm .getinTouchTit {
    font-size: 20px;
    font-weight: 400;
    color: var(--backgroundColor);
    margin-bottom: 14px;
}

footer .footgridSplt .customerGetintoForm {
    padding-top: 45px;
    padding-bottom: 30px;
}

footer .footgridSplt {
    display: grid;
    grid-template-columns: 1fr 350px;
}

footer .footgridSplt .footLinks {
    padding-top: 70px;
    padding-bottom: 60px;
}

footer .footgridSplt .footLinks figure {
    margin-bottom: 60px;
}

footer .fixedFoot .SocialIco .socilUl li {
    margin-right: 25px;
}

footer .fixedFoot .SocialIco .socilUl {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

footer .footgridSplt .footerLogo {
    max-width: 240px;
    width: 100%;
    object-fit: contain;
}

/* responsive  */

@media (max-width: 1200px) {
    .footCnt .footLogo {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0 auto 40px;
    }
}

@media (max-width: 991px) {
    footer .footAddres {
        grid-gap: 20px;
    }
}

@media (max-width: 850px) {
    footer .footAddres {
        grid-template-columns: 100%;
    }
}

@media (max-width: 767px) {
    footer .legal_mentionsStyl {
        margin-top: 25px;
    }

    footer .fixedFoot {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 30px 0;
    }

    .footer .footCopyright {
        margin-top: 0;
        text-align: center !important;
    }

    footer .footLinks ul {
        justify-content: flex-start !important;
        align-items: flex-start;
    }

    footer .footgridSplt {
        grid-template-columns: 100%;
    }

    .footCnt .footLinks,
    .footCnt .footMisc {
        width: 100%;
    }

    footer .footMisc .footSubscribe form .subsInp {
        width: 90%;
    }

    footer .footMisc .footSubscribe button {
        min-width: auto;
    }

    footer .footSocial {
        justify-content: center !important;
        margin: 10px auto;
    }

    footer .footCopyright {
        margin-top: 25px;
        text-align: center !important;
    }

    footer .footMisc .footSubscribe form {
        flex-wrap: nowrap;
    }

    footer .hashTag {
        margin-top: 10px;
    }

    footer .hashTag,
    footer .poweredBy {
        text-align: center;
    }

    footer .footLinks ul {
        justify-content: space-around;
        margin-bottom: 20px;
    }

    footer .footLogo img {
        margin-left: auto;
    }
}

@media (max-width: 600px) {
    footer .footLinks ul {
        margin-bottom: 5px;
        margin-top: 0;
    }

    footer .footLinks ul li:not(:last-child) {
        padding-right: 15px;
        padding-bottom: 6px;
    }

    footer .footgridSplt .footLinks figure {
        margin-bottom: 30px;
    }

    footer .rightnavLinks .footLang {
        display: none;
    }

    footer .fixedFoot .SocialIco .socilUl {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    footer .footCopyright {
        margin-top: 5px;
    }

    footer .rightnavLinks {
        text-align: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column-reverse;
    }

    footer .rightnavLinks {
        flex-wrap: wrap;
    }

    footer .fixedFoot {
        display: grid;
        grid-template-columns: 1fr;
        padding: 30px 0;
    }

    footer .footLinks ul:not(:last-child) {
        margin-inline-end: 0;
    }

    footer .footgridSplt .customerGetintoForm {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    footer .footAddres {
        grid-template-columns: 47% 47%;
    }

    footer .footLinks ul li {
        padding-right: 0;
        width: 47%;
    }

    footer .footgridSplt .footLinks {
        padding-top: 30px;
        padding-bottom: 0px;
    }
}

@media (max-width: 445px) {
    footer ul li a {
        font-size: 13px;
    }

    footer .footMisc .footSubscribe small {
        text-align: center;
        width: 100%;
        display: block;
    }

    footer .footLinks ul:not(:last-child) {
        margin-inline-end: 35px;
    }
}

@media (max-width: 375px) {
    footer .footLinks ul li:not(:last-child) {
        width: 100%;
    }

    footer .footAddres {
        grid-template-columns: 100%;
    }
}
