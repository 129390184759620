.subsWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
}

.subsTitle {
    color: var(--textColor);
    font-size: 30px;
    font-weight: 600;
}

label.colorPrimary {
    color: var(--primColor);
}

.subsWrapper .tmSwtBox label {
    font-weight: 500;
    font-size: 20px;
}

.subsWrapper .onbdrTost {
    font-weight: 500;
    text-align: left;
    margin-top: 5px;
}

.subsWrapper .tmSwtBox .MuiSwitch-root {
    padding: 6px;
}

.subsWrapper .tmSwtBox .MuiSwitch-track {
    background-color: var(--primColor);
    border-radius: 100px;
    opacity: 1;
}
.SubTablesCnt {
    overflow: auto;
}

.SubsTableWrpr {
    display: grid;
    /* grid-template-columns: 200px min(180px) min(180px) min(180px) min(180px) min(180px); */
    padding-top: 40px;
    padding-bottom: 20px;
    /* overflow: auto; */
    grid-template-columns: 200px repeat(auto-fit, minmax(130px, 1fr));
    gap: 10px;
    flex-wrap: wrap;
}

.planTableWrpr {
    padding: 0px 0;
    /* border: 1px solid red; */
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    /* border: 1px solid #f1f1f1; */
}

.SubsTableWrpr .colWrpr {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
}

.SubsTableWrpr .planCnt {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-left: 1px solid #f1f1f1;
    padding: 8px 2px; */
}

.SubsTableWrpr .planCnt .primButton {
    width: 100%;
    height: 51px;
}
.SubsTableWrpr .planCnt .primButton .MuiButton-contained.Mui-disabled {
    background: var(--primColor);
    color: white;
}

.SubsTableWrpr .planCnt:nth-child(1) {
    background: #f1f2ff;
}

.SubsTableWrpr .fstColmn {
    background: #f6f7ff;
}

.SubsTableWrpr .fstColmn div:nth-child(3) {
    text-transform: unset;
    font-weight: 600;
}

.SubsTableWrpr .colWrpr div {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
}

/* .SubsTableWrpr .colWrpr:not(:nth-child(2)) .ofera:nth-child(1) {
    color: transparent;
} */

.SubsTableWrpr .colWrpr:not(:nth-child(2)) div:nth-child(3) {
    /* text-transform: lowercase; */
}

.SubsTableWrpr .fstColmn div:nth-child(even) {
    background: #f1f2ff;
}

.SubsTableWrpr .plnColmn div:nth-child(even) {
    background: #f6f7ff;
}

.SubsTableWrpr .plnColmn.colWrpr .mesName {
    text-transform: lowercase;
}

.SubsTableWrpr .plnColmn.colWrpr:nth-child(2) .mesName {
    font-weight: 600;
}

.SubsTableWrpr .colWrpr .tblHdrVw {
    height: 80px;
}

.SubsTableWrpr .colWrpr .tblHdrVw h2 {
    /* font-family: 'Katibeh', cursive; */
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: capitalize;
    min-height: auto;
    margin-bottom: 5px;
}

.SubsTableWrpr .colWrpr .tblHdrVw h3 {
    color: var(--darkFont);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.SubsTableWrpr .colWrpr .tblHdrVw .plnTlDiv {
    height: unset;
}

.SubsTableWrpr .colWrpr .tblHdrVw .plnTlDiv .secButton {
    margin: 10px 0 20px;
}

.SubsTableWrpr .colWrpr .ntAvlBtn {
    color: #ff7777;
}

.SubsTableWrpr .colWrpr .avlBtn {
    color: #50d64c;
}

.subsWrapper .onbrdInfo {
    font-size: 15px;
}

.SubsTableWrpr .cntLnk {
    color: white;
    background: var(--primColor);
    padding: 7px 28px;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 25px;
}

/* .modalChildren .addUserModal {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.orderReviewBox {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px dashed gray;

} */

.modalChildren .addUserModal {
    max-width: 100%;
    margin: auto;
}

.modalChildren .addUserModal .primButton {
    text-align: center;
}

.modalChildren .addUserModal .primButton .MuiButton-root {
    width: auto;
    min-width: 150px;
}

.modalChildren .orderReviewBox {
    max-width: 600px;
    margin-bottom: 25px;
    margin: auto;
}

.subcardAdding {
    max-width: 600px;
    margin: auto;
}

.modalChildren .orderReviewBox .lftRvwBox {
    width: 100%;
}

.orderReviewBox h5 {
    margin-bottom: 20px;
}

.orderReviewBox ul {
    list-style: none;
    padding-left: 0;
    min-width: 500px;
}

.orderReviewBox ul .divider {
    border-top: 1px solid rgb(202, 202, 202);
    margin-top: 10px;
    padding-bottom: 10px;
}

.orderReviewBox ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderReviewBox .rtImg img {
    width: 120px;
    filter: brightness(0.8);
}

.orderReviewBox.bronze {
    border: 1px dashed #cd7f32;
    background: #fffaf4;
}

.orderReviewBox.silver {
    border: 1px dashed gray;
    background: #f5f5f5;
}

.orderReviewBox.silver .primButton {
    width: 200px;
    margin: 0 auto;
}

.orderReviewBox.silver img {
    filter: grayscale(1);
}

.activeTag {
    padding: 4px 30px;
    background: #a3aaf0;
    color: #fff;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    width: min-content;
    margin: 0 auto;
    font-size: 13px;
}

.plnColmn.colWrpr.active {
    box-shadow: 0px 0px 30px #a3aaf04d;
}

.plnColmn.colWrpr.active .mesName {
    /* font-weight: 700; */
}

.orderReviewBox.gold {
    border: 1px dashed #d4af37;
    background: #fff9e6;
}

.orderReviewBox.gold img {
    filter: brightness(1.1);
}

.orderReviewBox.enterprise {
    border: 1px dashed var(--primColor);
    background: #f6f7ff;
}

.orderReviewBox.enterprise img {
    filter: invert(0.8);
}

.subPayPopup .selectAddress label {
    border: 1px dashed gray;
    border-radius: 5px;
    width: 100%;
}

.subPayPopup .selectAddress {
    width: 100%;
}

.subPayPopup .selectAddress .savedAddressCard {
    padding-left: 20px;
    padding-right: 10px;
}

.subPayPopup .selectAddress .savedAddressCard div h5 {
    font-size: 15px;
}

.subPayPopup .selectAddress .savedAddressCard .edtDlBtns {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.subPayPopup .selectAddress .savedAddressCard .edtDlBtns .MuiIconButton-root {
    padding: 4px;
}

.customDialog.upgradeModal .MuiTypography-h6 div:nth-child(1) {
    justify-content: space-between !important;
}

.subPayPopup .chCntGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 15px;
}

@media (max-width: 1440px) {
    .SubsTableWrpr {
        grid-template-columns: 170px repeat(auto-fit, minmax(135px, 1fr));
        gap: 5px;
    }

    .subsWrapper .tmSwtBox label {
        font-size: 18px;
    }

    .SubsTableWrpr .colWrpr .tblHdrVw h3,
    .SubsTableWrpr .colWrpr .tblHdrVw h2 {
        font-size: 16px;
    }
}

@media (max-width: 1366px) {
    /* .SubsTableWrpr {
        grid-template-columns: 200px min(180px) min(180px) min(180px) min(170px) min(180px);
    } */
}

@media (max-width: 1250px) {
    .SubsTableWrpr {
        /* grid-template-columns: 200px min(170px) min(170px) min(170px) min(170px) min(170px); */
        gap: 10px;
    }

    .SubsTableWrpr .colWrpr .tblHdrVw h3,
    .SubsTableWrpr .colWrpr .tblHdrVw h2 {
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .subsTitle {
        font-size: 18px;
    }

    .SubsTableWrpr {
        grid-template-columns: 200px min(170px) min(170px) min(170px) min(170px) min(170px);
        width: fit-content;
    }

    .SubsTableWrpr .colWrpr .tblHdrVw h3,
    .SubsTableWrpr .colWrpr .tblHdrVw h2 {
        font-size: 14px;
    }

    .orderReviewBox .lftRvwBox,
    .orderReviewBox ul {
        width: 100%;
        min-width: fit-content;
    }

    .customDialog.upgradeModal .MuiPaper-root.MuiDialog-paper {
        min-width: 100%;
    }

    .orderReviewBox .rtImg {
        display: none;
    }

    .modalChildren .orderReviewBox .lftRvwBox {
        width: 100%;
    }
}

@media (max-width: 345px) {
    .SubsTableWrpr {
        grid-template-columns: 100%;
    }
}
