.SiteCookiesModal {
    background-color: #fff;
    padding: 30px;
    display: grid;
    grid-template-columns: 2% 1fr auto;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
}

.SiteCookiesModal p {
    font-size: 13px;
}

.SiteCookiesModal .cookiesBtnGrp span {
    font-size: 16px;
    text-transform: capitalize;
}

.SiteCookiesModal .cookiesBtnGrp {
    display: flex;
    gap: 10px;
}

.SiteCookiesModal span.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
}

.cookiesDrawer,
.cookiesDrawer .MuiBackdrop-root {
    bottom: auto !important;
    height: 200px;
    top: auto !important;
}

@media (width <=768px) {
    .SiteCookiesModal {
        grid-template-columns: auto 1fr;
    }

    .SiteCookiesModal .cookiesBtnGrp {
        grid-column: 1/3;
        justify-content: flex-end;
    }
}

@media (width <=600px) {
    .SiteCookiesModal span.icon {
        display: none;
    }
}

@media (width <=475px) {
    .SiteCookiesModal .primButton button,
    .SiteCookiesModal .secButton button,
    .SiteCookiesModal .tertButton button {
        height: 35px;
    }

    .SiteCookiesModal .cookiesBtnGrp span {
        font-size: 14px;
    }

    .SiteCookiesModal h4 {
        font-size: 16px;
    }

    .SiteCookiesModal p {
        font-size: 12px;
    }

    .SiteCookiesModal {
        grid-template-columns: 1fr;
        padding: 15px 15px;
        gap: 10px;
    }

    .SiteCookiesModal .cookiesBtnGrp {
        grid-column: unset;
        justify-content: center;
    }
}
