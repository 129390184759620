.customDialog.subscriptionModal .MuiDialogContent-root {
    padding: 0px 52px;
    padding-bottom: 75px;
}
.subscriptionModal {
}
.subscriptionModal .ModalLogoSec {
    text-align: center;
    margin-bottom: 56px;
}
.subscriptionModal .subcriptionBody {
    text-align: center;
}
.subscriptionModal .subcriptionBody .tit {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    color: var(--primColor);
}
.subscriptionModal .subcriptionBody .info {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--darkGrey);
    margin-top: 25px;
    margin-bottom: 30px;
}
.subscriptionModal .subcriptionBody .subcriptionPlansTable table {
    display: flex;
}
.subscriptionModal .subcriptionBody .subcriptionPlansTable table thead tr {
    flex-direction: column;
    display: flex;
}

.subscriptionModal .subcriptionBody .subcriptionPlansTable table tbody tr:nth-child(even) td {
    background-color: #f2f2f2;
}

.subscriptionModal .subcriptionBody .subcriptionPlansTable table tbody tr td .subcriptionBadge {
    position: absolute;
    top: -13px;
    left: 0;
    bottom: auto;
    right: 0;
    background-color: var(--primColor);
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: var(--backgroundColor);
    margin: 0 auto;
    min-height: 21px;
    padding: 5px;
}

.subscriptionModal .subcriptionBody .subcriptionPlansTable table tbody tr td {
    width: 100%;
    min-width: 150px;
    max-width: 150px;
    vertical-align: middle;
    height: min(51px);
    border: unset;
    position: relative;
    white-space: nowrap;
}

.subscriptionModal .subcriptionBody .subcriptionPlansTable table tbody tr:nth-child(1) td {
    background-color: var(--textColor);
    color: var(--backgroundColor);
    font-size: 14px;
    font-weight: 400;
}

.subscriptionModal .subcriptionBody .subcriptionPlansTable table thead th:nth-child(1) {
    background-color: var(--primColor);
    color: var(--backgroundColor);
}

.subscriptionModal .subcriptionBody .subcriptionPlansTable table thead th {
    width: 100%;
    min-width: 230px;
    max-width: 230px;
    border: unset;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    height: min(51px);
    vertical-align: middle;
    color: var(--textColor);
}
