.checkout {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.checkout .checkoutHead {
    font-size: 16px;
    color: #333333;
    font-weight: 700;
    margin: 0;
    text-align: start;
    margin-bottom: 10px;
    margin-top: 30px;
}

.checkout .checkoutHead ~ span {
    padding-inline-start: 10px;
    display: inline-block;
}

.checkout .checkoutSecAction {
    background: #fff;
    padding: 15px;
    margin-top: 20px;
}

.checkout .checkoutSecAction label {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    padding-inline-end: 10px;
}

.actionButton .secButton {
    margin-right: 5px;
}

.checkout .checkoutSecAction h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    display: inline;
}

.checkout .selectDate {
    width: 200px;
    height: 100px;
}

.checkout .selectDate label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 80px;
    border: 1px dashed #777;
}

.checkout .selectDate input:checked ~ label {
    background: #ddf5eb;
    color: #06b473;
    border: 1px solid #8cddbf;
    position: relative;
}

.checkout .selectDate input:checked ~ label:after {
    content: '\f058';
    position: absolute;
    top: 50%;
    right: 15px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #06b473;
    transform: translateY(-50%);
}

.checkout .selectDate label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-inline-end: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.checkout .selectDate label p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 2px;
}

.checkout .selectDate label span {
    margin: 0;
    font-size: 14px;
}

.checkout .selectDate label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.checkout .chCnt.timeSlots .selectDate label p {
    text-align: center;
}

.checkout .schedule,
.checkout .payment,
.checkout .review,
.checkout .pickupAddress,
.checkout .billingInfo {
    margin-bottom: 20px;
    background: #fff;
    padding: 0;
}

.checkout .review:not(:last-child) {
    /* border-bottom: 1px solid #e4e4e4; */
    padding-bottom: 25px;
}

.checkout .pickupAddress {
    text-align: start;
}

.checkout .pickupAddress h6 {
    font-size: 14px;
    color: #0e131f;
    margin-top: 10px;
}

.checkout .pickupAddress h5 {
    font-size: 15px;
    color: #333333;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 20px;
}

.checkout .pickupAddress h5 .MuiSvgIcon-root {
    color: var(--secColor);
}

.checkout .schedule .scheduleError {
    font-size: 13px;
    color: #f44336;
    text-align: start;
    margin-top: 2px;
}

.checkout .payment .cardDetails {
    margin-top: 15px;
}

.checkout .payment .cardDetails h4 {
    text-align: start;
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 15px 0;
}

.checkout .selectTime .customSelect,
.checkout .paymentMethod .customSelect {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: #fff;
    margin-top: 20px;
    min-width: 200px;
}

.checkout .selectTime > label,
.checkout .paymentMethod > label {
    margin-inline-end: 20px;
}

.checkout .payment .paymentMethod {
    background: #fff;
    margin: 20px 0 10px 0;
    padding: 20px 0;
}

.checkout .schedule .selectTime {
    background: #fff;
    margin: 10px 0;
    padding: 20px 0 0 0;
}

.checkout .payment .paymentMethod .customSelect,
.checkout .schedule .selectTime .customSelect {
    margin: 0;
}

.checkout .selectCard label {
    margin: 0;
}

.checkout .selectCard p {
    margin: 0;
    text-align: start;
}

.checkout .selectCard table {
    width: 100%;
}

.checkout .selectCard table tr {
    border-bottom: 5px solid whitesmoke;
    border: 1px dashed #b6b6b6;
}

.checkout .selectCard table td {
    padding: 10px;
}

.checkout .checkoutLt {
    width: 68%;
}

.checkout .checkoutLt .isMultiCardSelection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.checkout .checkoutLt .subHead {
    margin: 0;
    text-transform: initial;
    margin-inline-end: 15px;
    font-size: 16px;
}

.checkout .checkoutLt .cartItem {
    margin-bottom: 0;
}

.checkout .checkoutLt .noMarginBottom .customInput {
    margin-bottom: 0;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-label {
    width: max-content;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-root {
    margin: 0;
    margin-inline-end: 15px;
}

.checkout .checkoutLt .isMultiCardSelection .MuiButtonBase-root.MuiListItem-root {
    width: max-content;
    margin-inline-end: 20px;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormGroup-root {
    flex-wrap: nowrap;
}

.billingAddress {
    text-align: start;
}

.billingAddress h4 {
    font-size: 16px;
    font-weight: 600;
}

.billingAddress p {
    margin-bottom: 0;
    margin-top: 5px;
}

.billingInfo button {
    color: var(--primColor);
    border-color: var(--primColor);
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControl-root,
.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-root {
    width: 100%;
}

.checkout .checkoutRt {
    width: 30%;
    text-align: start;
}

.checkout .checkoutRt .orderSummary {
    background: #fff;
    padding: 20px;
    position: sticky;
    top: 15px;
    border-radius: 5px;
    border: 1px dashed #a6adb4;
}

.checkout .checkoutRt .primButton {
    margin-bottom: 0px;
    margin-top: 0px;
}

.checkout .checkoutRt .customSelect {
    margin-top: 20px;
}

.checkout .checkoutRt .mdOfPymt .customSelect {
    margin-top: 0px;
}

.checkout .checkoutRt h3 {
    font-size: 20px;
    color: var(--secColor);
    font-weight: 700;
    margin-bottom: 20px;
}

.checkout .checkoutRt > p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
}

.checkout .checkoutRt > p,
.checkout .checkoutRt p label {
    font-size: 16px;
    color: #111111;
    font-weight: 600;
    margin-bottom: 0px;
}

.checkout .checkoutRt p {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout .checkoutRt h4 {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
    margin-bottom: 0;
}

.checkout .customInput,
.checkout .customSelect {
    margin-bottom: 20px;
}

.checkout .orderSummarySection .primButton {
    margin-top: 10px;
}

.checkout .orderSummarySection h6 {
    font-size: 16px;
    color: #111111;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

.checkout .orderSummarySection h6 span {
    padding-inline-start: 5px;
}

.checkout .orderSummarySection .osBack {
    width: 100%;
    margin-top: 10px;
}

.checkout .checkoutRt h4 label {
    margin-bottom: 0;
}

.existingAppointmentBanner {
    background: #d8f1ff;
    padding: 30px 15px;
    position: relative;
    overflow: hidden;
}

.existingAppointmentBanner > div {
    z-index: 1;
}

.existingAppointmentBanner .material-icons {
    position: absolute;
    font-size: 80px;
    color: #afe3ff;
    top: -10px;
    left: -30px;
}

.existingAppointmentBanner h2 {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: start;
}

.existingAppointmentBanner h4 {
    font-size: 1rem;
    text-align: start;
    margin-bottom: 0;
}

.existingAppointmentBanner .primButton {
    margin-bottom: 0;
}

.partialPaymentToggle .MuiFormControlLabel-root {
    margin-inline-end: 0;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.partialPaymentToggle .MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: var(--secColor);
    font-weight: 600;
}

.checkout .cartSkeletonBody {
    margin: 60px 0;
}

.checkout .cartSkeletonBody .csbItem .listViewSkeleton {
    background: #fff;
    grid-template-columns: 1fr 3fr 1.8fr;
}

.checkout .cartSkeletonBody .csbItem .listViewSkeleton > div:nth-child(2) {
    margin: 0 40px 0 20px;
}

.checkout .pickupAddress h5 {
    background: #fef1e9;
    padding: 10px;
}

.checkout .pickupAddress .adrsWrapper {
    background: #f8f8f8;
    padding: 10px;
    color: #333333;
}

.checkout .pickupAddress .adrsWrapper .inrWrpr {
    color: #333333;
}

.checkout .pickupAddress .adrsWrapper .MuiSvgIcon-root {
    color: gray;
}

.checkout .checkoutLt .mdOfDlvrWrpr {
    margin: 25px 0;
}

.checkout .checkoutLt .mdOfDlvrWrpr .RadioBox {
    display: flex;
    align-items: center;
}

.checkout .checkoutLt .mdOfDlvrWrpr .RadioBox h6 {
    margin: 0;
    margin-right: 45px;
    font-size: 20px;
    color: #333333;
}

.checkout .checkoutLt .spngAdrDtls .grdWrpr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 10px;
}

.checkout .checkoutLt .spngAdrDtls .shpAdrsTitle {
    font-size: 20px;
    text-align: left;
    color: #333333;
    margin-bottom: 20px;
}

.checkout .checkoutLt .spngAdrDtls .selectDate {
    max-width: 355px;
    width: 100%;
    height: 175px;
}

.checkout .checkoutLt .spngAdrDtls .selectDate label {
    height: 100%;
    margin: 0;
}

.checkout .checkoutLt .spngAdrDtls .selectDate label .MuiListItem-button {
    padding: 25px;
}

.checkout .checkoutLt .spngAdrDtls .selectDate label h6 {
    font-size: 16px;
    color: #444d63;
}

.checkout .checkoutLt .spngAdrDtls .selectDate label p {
    font-size: 16px;
    color: #788094;
}

.checkout .checkoutLt .spngAdrDtls .selectDate input:checked ~ label:after {
    top: 21%;
    right: 20px;
    font-size: 22px;
}

.checkoutLt .spngAdrDtls .adNwAdrs.selectDate .MuiListItem-button {
    text-align: center;
}

.checkout .selectShipping {
    width: 100%;
}

.checkout .shpngDtlCntnr .MuiButtonBase-root.MuiListItem-button {
    border: 1px dashed #777;
    border-radius: 5px;
    padding-top: 10px;
}

.checkout .shpngDtlCntnr .MuiButtonBase-root.checkedButton {
    border: 1px dashed #8cddbf;
    background: #ddf5eb;
    border-radius: 5px;
    padding-top: 10px;
}

.checkout .selectShipping .chkdIcon.MuiSvgIcon-root {
    color: #06b473;
    font-size: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
}

.checkout .shpngDtlCntnr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}
.custom-error {
    text-align: left;
    margin-top: 13px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: #f44336;
}

@media (max-width: 1024px) {
    .checkoutCnt {
        flex-wrap: wrap;
        /* flex-direction: column-reverse; */
    }
    .checkout .checkoutLt,
    .checkout .checkoutRt {
        width: 100%;
    }
    .checkout .checkoutRt .orderSummary {
        position: static;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .checkout .checkoutRt .orderSummary .primButton button {
        width: 200px;
        margin-top: 20px;
        float: right;
    }
    .checkoutSecAction {
        display: none !important;
    }
}

@media (max-width: 845px) {
    .existingAppointmentBanner .primButton {
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .existingAppointmentBanner {
        padding: 20px 15px;
    }
    .existingAppointmentBanner h2 {
        font-size: 1.2rem;
    }
    .existingAppointmentBanner h4 {
        font-size: 0.9rem;
    }
    .chCnt {
        flex-wrap: wrap;
    }
    .checkout .selectDate {
        margin-bottom: 15px;
        margin-inline-end: 15px;
        width: 45%;
        height: 70px;
    }
    .checkout .selectDate label {
        margin-inline-end: 0;
    }
    .checkout .selectDate label p {
        width: 100%;
    }
    .checkout .selectDate input:checked ~ label:after {
        top: 20%;
    }
    .checkout .checkoutRt .orderSummary .primButton button {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .checkout .checkoutLt .noMarginBottom .customInput {
        margin-bottom: 30px;
    }
    .checkout .payment {
        padding-bottom: 0;
    }
    .checkout .schedule .selectTime {
        padding-top: 0;
    }
}

@media (max-width: 500px) {
    .checkout {
        padding-top: 10px;
    }
    .checkout .selectDate {
        width: 100%;
        margin-inline-end: 0px;
        height: max-content;
    }
    .checkout .selectDate label p {
        width: max-content;
    }

    .checkout .selectDate label span {
        /* margin-inline-start: auto; */
    }

    .checkout .checkoutLt .noMarginBottom .customInput {
        margin-bottom: 25px;
    }

    .checkout .checkoutLt .subHead {
        font-size: 14px;
    }
    .checkout .checkoutHead {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .checkout .payment .paymentMethod {
        margin: 10px 0 10px 0;
        padding: 10px 0;
    }
    .checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-label {
        font-size: 14px;
    }
    .checkout .checkoutRt .orderSummary .primButton button {
        width: 100%;
    }
}

@media (max-width: 440px) {
    .checkout .payment .paymentMethod,
    .checkout .schedule .selectTime {
        flex-wrap: wrap;
    }

    .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .checkout .selectTime .customSelect,
    .checkout .paymentMethod .customSelect {
        width: 100%;
    }

    .checkout .schedule .selectTime .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

@media (max-width: 400px) {
    .checkout .checkoutLt .isMultiCardSelection {
        flex-wrap: wrap;
    }

    .checkout .checkoutLt .isMultiCardSelection .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
