.productInvoice {
    padding: 2rem !important;
    margin: 30px auto 30px;
    text-align: left;
    font-size: 14px;
}

.productInvoice .invLogo {
    width: 150px;
}

.invoiceUnpaid {
    color: var(--primColor);
}

.productInvoices .card-list {
    display: grid;
    margin-top: 15px;
    grid-gap: 30px;
    gap: 30px;

    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}
.productInvoices .card-list div {
    font-size: 14px;
    font-weight: 400;
    color: #7b7b7b;
    text-align: start;
}
.productInvoices .card-list span {
    font-size: 14px;
    font-weight: 400;
    text-align: end;
}

.productInvoice span.material-icons {
    padding-right: 5px;
}

.productInvoice .right {
    text-align: right !important;
}

.productInvTot span {
    font-weight: 500;
}

.productInvoice .invoiceView {
    margin-bottom: 30px;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    border-top: 4px solid var(--primColor);
    background: #fff;
}

.productInvoice .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6f2;
}

.productInvoice .invoiceView h3 {
    font-size: 20px;
}

.productInvoice .invoiceTitle {
    font-size: 18px;
    margin: 0px 0 15px 0;
    font-weight: 600;
    color: #3a3a3a;
    text-align: center;
    text-transform: uppercase;
}

.productInvoice .invoiceSubTitle {
    font-size: 18px;
    margin: 35px 0 15px 0;
    font-weight: 600;
    color: #3a3a3a;
    text-align: left;
    text-transform: uppercase;
}

.productInvoice .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(241, 241, 241);
}

.productInvoice .invoiceView .table thead th {
    font-weight: 600;
    font-size: 15px;
}

.productInvoice .invoiceView .table tbody td {
    font-weight: 400;
    font-size: 15px;
}

.productInvoice .invoiceView h5 {
    font-size: 15px;
    line-height: 26px;
    color: #3d405c;
    margin: 0px 0px 15px 0px;
}

.productInvoice .text-dark {
    color: #3d405c !important;
}

.productInvoice .transDet .invDiv {
    /* width: 25%; */
    margin-bottom: 15px;
}

.productInvoice .transDet table th:last-child,
.productInvoice .transDet table td:last-child {
    text-align: right;
}

.productInvoice .transDet p {
    font-size: 15px;
    margin-bottom: 0px;
    color: #5f5f5f;
}

.productInvoice .transDet span {
    width: 100%;
    font-weight: 500;
}

.productInvoice .cartItem {
    padding: 0;
    margin-bottom: 15px;
}

.productInvoice .thankText {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #7b7b7b;
    letter-spacing: 0.5px;
}

.productInvoice .transDet table {
    width: 100%;
    border-collapse: collapse;
}

/* Zebra striping */
.productInvoice .transDet table tr:nth-of-type(odd) {
    background: #fff;
    margin-bottom: 10px;
}

.productInvoice .transDet table th {
    background: #333;
    color: white;
    font-weight: bold;
}

.productInvoice .transDet table td,
.productInvoice .transDet table th {
    padding: 6px;
    border: 1px solid #e6e6e6;
    text-align: left;
}

.invoiceNotes.customDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 600px;
}

@media only screen and (max-width: 767px) {
    .productInvTot tbody td {
        width: 50%;
        display: inline-block;
    }

    .productInvoice .invoiceView .table tbody td {
        padding: 10px;
    }

    .productInvoice {
        margin: 15px auto 15px;
    }

    .productInvoice .transDet table {
        border: none;
    }

    .productInvoice .transDet table td,
    .productInvoice .transDet table th {
        text-align: right;
    }

    /* Force table to not be like tables anymore */
    .productInvoice .transDet table,
    .productInvoice thead,
    .productInvoice tbody,
    .productInvoice th,
    .productInvoice td,
    .productInvoice tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .productInvoice .transDet thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    /* .productInvoice .transDet tr {
        border: 1px solid #ccc;
    } */

    .productInvoice .transDet td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    .productInvoice .transDet td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 9px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-title) ':';
        text-align: left;
        font-weight: 600;
        color: #000;
    }

    .productInvoice .invoiceView h3 {
        font-size: 16px;
    }

    .nellisInvTot tbody tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nellisInvTot tbody td {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .nellisInvTot tbody td {
        text-align: left !important;
        font-size: 13px !important;
    }

    .invoiceView .card-body {
        padding: 12px;
    }
}

@media (max-width: 514px) {
    .dashboardRt .exprtBtns {
        margin: auto;
        text-align: center;
        justify-content: center !important;
        margin-top: 15px;
    }
    .productInvoices .card-list {
        display: grid;
        margin-top: 15px;

        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    .productInvoice .invoiceView h5 {
        margin-bottom: 5px !important;
    }

    .invDate {
        text-align: center;
        margin-top: 10px;
    }

    .card-body .invAddr {
        text-align: left !important;
    }

    .card-body .invAddr:last-child {
        margin-top: 20px;
    }

    .productInvoice .invoiceSubTitle {
        font-size: 14px;
        margin-top: 25px !important;
    }

    .productInvoice .transDet td:before {
        font-size: 14px;
    }

    .cartItem .listContent .listProdTitle {
        font-size: 15px;
    }

    .productInvoice {
        padding: 5px !important;
    }

    .card-header {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .card-header a {
        margin: 0 auto;
    }

    .card-body > div {
        /* width: 100%; */
        float: none;
        text-align: center;
        margin-top: 10px;
    }

    .productInvoice .card-header {
        padding: 15px !important;
    }

    .productInvoice .invLogo {
        width: 165px !important;
    }

    .productInvoice .card-header .text-left {
        text-align: center !important;
    }

    .productInvoice {
        margin: 0 auto;
    }

    .productInvoice .invoiceSubTitle {
        font-size: 16px;
        margin: 25px 0 15px 0;
        margin-top: 30px !important;
    }

    .card-header a {
        margin: 0;
        margin-right: 0;
    }
}
.invoice_status.sameLineAdd {
    font-style: italic;
    font-weight: 700;
}

@media (max-width: 400px) {
    .productInvoices .card-list {
        display: grid;
        margin-top: 15px;
        grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
    }
}
@media print {
    .productInvoice .transDet table th {
        background-color: #fff !important;
        color: #333 !important;
    }
    .cartItem .pclImg img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    .row .sameLineAdd {
        width: auto;
        display: inline;
    }
    .row .sameLineAdd.invoiceId {
        width: 60%;
    }
    .row .colonSameLine,
    .row .colonSameLine span {
        margin: 0 !important;
        padding: 0 !important;
        width: 2px;
        display: inline !important;
    }
    .row .padBot {
        padding-bottom: 15px;
    }
    .row .mobileprint {
        display: flex !important;
        align-items: center;
    }
    .row .mobileprintCont {
        display: flex;
        gap: 15px;
    }
}
.row .mobileprint {
    display: none;
}
.fullScreenPopup .bottom-border {
    border: none !important;
}
.fullScreenPopup .invDate.invDateCus {
    display: none;
}
.fullScreenPopup .invDate.invDateCust {
    display: block !important;
}

@media print {
    .fullScreenPopup .MuiAppBar-colorPrimary,
    .fullScreenPopup .MuiToolbar-root.fspToolbar,
    .fullScreenPopup .MuiToolbar-root.fspToolbar button,
    .fullScreenPopup .MuiToolbar-root.fspToolbar h6 {
        display: none !important;
    }
    .fullScreenPopup .invDate.invDateCust {
        display: none !important;
    }
    .fullScreenPopup .invDate.invDateCus {
        display: none !important;
        float: left !important;
        color: #000 !important;
    }
    .fullScreenPopup .invDate.invDateCus {
        display: block !important;
        float: left !important;
        width: 100%;
        clear: both !important;
    }
    .fullScreenPopup .invAddr.text-right h5.mb-3,
    .fullScreenPopup .invAddr.text-right .textChnage div {
        font-weight: 600 !important;
        font-size: 16px !important;
        display: inline !important;
    }
    .fullScreenPopup .invDate.invDateCus h5 b {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: black !important;
    }
    .fullScreenPopup .productInvoice .invoiceSubTitle {
        margin-top: 100px !important;
    }
    .fullScreenPopup .textChangeClo {
        font-size: 16px !important;
    }
    /* .modalChildren div {
        background-color: white !important;
        transition: none !important;
    } */
    .fullScreenPopup .printCustSeller {
        display: block !important;
    }
    .fullScreenPopup .printCustSeller .invAddr.text-right {
        text-align: left !important;
    }
    .fullScreenPopup .bottom-border {
        border-bottom: 4px solid black !important;
    }
}
