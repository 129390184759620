.maintenancePage {
    padding: 120px 60px;
}

.maintenancePage > img {
    max-width: 500px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.maintenancePage > h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 30px 0 0;
}

@media (max-width: 767px) {
    .maintenancePage {
        padding: 45px;
    }
}

@media (max-width: 500px) {
    .maintenancePage > h2 {
        font-size: 18px;
    }
}
