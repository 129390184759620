header.mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

header.mainHeader {
    padding: 10px 0;
    height: 90px;
    /* box-shadow: 0 3px 6px #00000029; */
    /* border-bottom: 1px solid #e6e6e6; */
    position: relative;
    box-shadow: -2px -7px 17px 1px #00000063;
}

.topHeader {
    height: 44px;
    border-bottom: 1px solid #e6e6e6;
}

.topHeader ul,
.mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.topHeader ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--textColor);
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
}

.topHeader .material-icons,
.topHeader li img {
    padding-inline-end: 10px;
}

.topHeader .linkDivider {
    padding: 0 10px;
}

header .headLt {
    /* margin-inline-end: 20px; */
}

header .headerGridLayout .ASlogo {
    display: grid;
    justify-content: center;
    align-items: center;
}

header .headerGridLayout .ASlogo img {
    max-width: 276px;
    width: auto;
    height: auto;
}

.languageMenu img {
    padding-inline-end: 10px;
}

header .menuRtOtr {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}

header.mainHeader .headRt.deskNav .MuiButton-root {
    padding: 0 5px;
    min-width: auto;
}

header.mainHeader .headRt.deskNav .MuiButton-root .userName {
    white-space: nowrap;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
}

header .switchUserDD .customSelect {
    margin-bottom: 0;
}

header .switchUserDD .customSelect .MuiOutlinedInput-root {
    height: 45px;
}

header .switchUserDD .customSelect .MuiInput-underline:after,
header .switchUserDD .customSelect .MuiInput-underline::before {
    display: none;
}

header .headRt ul li:not(:last-child) {
    margin-inline-end: 15px;
}

header .headRt ul li.headCart {
    margin-inline-end: 0;
}

header .headRt ul li.headCart > button .MuiBadge-badge {
    color: var(--primColor);
    background-color: #ffffff !important;
    border: 1px solid var(--primColor);
}

/* header .headRt ul li button.MuiButton-text .MuiButton-label {
    color: var(--textColor);
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
} */

header .headRt ul li button.MuiButton-text .MuiButton-label img[alt='searchIcon'] {
    width: 22px;
}

header .headRt ul li button.MuiButton-text .MuiButton-label img {
    width: 25px;
    object-fit: contain;
}

header .headRt ul li a {
    color: var(--textColor);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 0 0.5rem;
    text-transform: capitalize;
    text-decoration: none;
    transition: 200ms color ease-in-out;
    position: relative;
}

header .headRt ul li a.active {
    color: var(--primColor);
}

header .headRt ul .languageSwitch {
    display: inline-flex;
    align-items: center;
}

header .headRt ul .languageSwitch a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

header .headRt ul .languageSwitch a img {
    margin-inline-end: 10px;
}

header .headRt ul .languageSwitch .material-icons {
    font-size: 32px;
    color: #838383;
}

header .headLt .ASlogo {
    max-width: 250px;
}

header .headLt .headerSearchForm {
    background: #fff;
    border-radius: 25px;
    max-width: 370px;
    height: 45px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-inline-start: 0;
    justify-content: space-between;
}

header .headLt .headerSearchForm input {
    border: none;
    margin-inline-end: 0;
    height: inherit;
    background: inherit;
    border-radius: 25px;
    width: 100%;
    /* border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    padding: 0px 0px 0px 0px;
    /* color: var(--primColor); */
    color: #676767;
    width: -webkit-fill-available;
}

header .headLt .headerSearchForm button {
    color: #676767;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--primColor); */
    border-radius: 50%;
    width: 42px;
    height: 42px;
    margin-inline-end: 1px;
    min-width: fit-content;
}

header .headLt .headerSearchForm button .material-icons {
    width: 24px;
    height: 24px;
    overflow: hidden;
}

header .headLt .headerSearchForm input:focus,
header .headLt .headerSearchForm button:focus {
    border: none;
    box-shadow: none;
}

header .headLt .headerSearchForm input::placeholder {
    color: var(--greyPrime100);
}

/* header .headRt ul li a:hover {
    color: #212121;
} */

header .headRt ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

header .headRt ul li a.active:after {
    width: 100%;
    left: 0;
}

header .headRt ul li a:hover:after {
    width: 100%;
    left: 0;
}

header .headRt ul li span.material-icons {
    color: #646464;
    font-size: 28px;
    font-weight: 700;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.searchSlide {
    padding-top: 20px;
}

.searchSlide h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
}

.searchSlide h3 span {
    margin-inline-start: 10px;
    font-size: 15px;
    text-transform: capitalize;
    color: #808080;
    font-weight: 600;
}

.searchSlide .searchHeaderInput {
    height: 50px;
}

.searchSlide .searchHeaderInput input {
    height: inherit;
}

.searchSlide .searchHeaderInput input:focus {
    box-shadow: none;
    border-color: var(--primColor);
}

.searchSlide .searchHeaderInput .input-group-prepend {
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    z-index: 100;
    background: #fff;
    color: var(--primColor);
    padding: 0;
    border: none;
}

.searchSlide .searchHeaderInput .input-group-prepend .MuiButton-label {
    height: 100%;
}

.searchSlide .searchHeaderInput .input-group-prepend .input-group-text {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    border: none;
    display: flex;
    justify-content: center;
    background: #fff;
    color: var(--primColor);
}

.popularCatg .catgType label h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    width: 100%;
    color: #313131;
    text-align: center;
    margin-top: 10px;
    line-height: 22px;
}

.popularCatg .catgType .iconCnt {
    background: #efefef;
    padding: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popularCatg .catgType label span {
    font-size: 32px;
    color: #848484;
}

.popularCatg .catgType label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.searchSlide h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    color: #717171;
    margin-top: 30px;
}

.popularCatg .catgType {
    width: 33%;
    height: 90px;
    margin-bottom: 15px;
}

.popularCatg .catgType label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px dashed #777;
}

.popularCatg .catgType input:checked ~ label {
    background: var(--primColor);
    color: #fff;
    border: 1px dashed var(--primColor);
}

.popularCatg .catgType input:checked ~ label h4 {
    color: #fff;
}

.popularCatg .catgType input:checked ~ label span {
    color: #ffc6bb;
}

.popularCatg .catgType input:checked ~ label .iconCnt {
    background: #ca2d0f;
}

.popularCatg .catgType label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-inline-end: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.headerDrawer .languageSwitch a > img {
    padding-inline-end: 12px;
}

.respHeaderSearch .headerSearchForm .customSearch {
    width: 100%;
}

.respHeaderSearch .headerSearchForm {
    flex-wrap: nowrap;
    padding: 10px 15px;
}

.respHeaderSearch .headerSearchForm input {
    width: 100%;
    width: 100%;
    border-radius: 25px;
    background: var(--accentColor);
    color: var(--greyPrime100);
    height: 45px;
    padding-inline-start: 20px;
    border-color: transparent;
}

/* .respHeaderSearch .headerSearchForm button {
    min-width: max-content;
    background: var(--accentColor);
    margin-inline-start: 5px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--primColor);
} */

.headerDropMenu {
    padding-left: 0;
}

/* 
.headerDropMenu .MuiPopover-paper {
    top: 100px !important;
    right: 30px;
    left: initial !important;
}

.headerDropMenu.rtl .MuiPopover-paper {
    right: initial;
    left: 30px !important;
} */

.langDropMenu .MuiPopover-paper {
    top: 40px !important;
    left: initial !important;
    right: 30px;
    width: 150px;
}

.langDropMenu.rtl .MuiPopover-paper {
    right: initial;
    left: 30px !important;
}

.headerDropMenu .MuiButtonBase-root {
    color: #6f6f6f;
    padding: 10px 15px;
}

.headerDropMenu .MuiButtonBase-root:hover {
    color: #313131;
}

.sideNav ul .MuiListItem-button span.gavel {
    transform: rotateY(180deg);
    padding-inline-end: 0px !important;
    padding-top: 0px;
    padding-inline-start: 10px;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}

.headerDropMenu .MuiButtonBase-root .material-icons.gavel {
    transform: rotateY(180deg);
    padding-inline-end: 0px !important;
    padding-top: 0px;
    padding-inline-start: 10px;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}

.headerDropMenu .MuiButtonBase-root .material-icons {
    padding-inline-end: 10px;
}

.headerDropMenu .MuiPopover-paper .MuiBadge-badge {
    right: 15px;
    border: 1px solid #fff;
}

.headerDropMenu .MuiButtonBase-root .MuiBadge-root .MuiBadge-badge {
    right: 10px;
}

.headRt.respNav .navRespLinks.wrappfixBtn .MuiListItem-root {
    width: auto;
}

.headRt.respNav .navRespLinks.wrappfixBtn {
    position: sticky;
    bottom: 0;
    background: #ebebeb;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* header 16/05/23 */
.headerGridLayout {
    display: grid;
    grid-template-columns: 38% 1fr 38%;
    padding: 12px 0;
}

.searchPopOver .MuiPopover-paper {
    width: 100%;
    box-shadow: unset;
    min-width: 100%;
}

/* end */

.aa-Panel .aa-ItemTitle mark {
    background-color: #ffe0e1;
    color: var(--greyPrime100);
}

header .customSearch .aa-Autocomplete form {
    border: none;
}

header .customSearch .aa-Autocomplete .aa-SubmitIcon {
    color: #676767;
}

header .customSearch .aa-Form:focus-within {
    box-shadow: none;
}

.aa-Panel {
    z-index: 9999;
    min-width: 600px;
    color: var(--greyPrime100);
}
/* Badege */
.badgeCounthead {
    background: #e33e3e;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 45px;
    padding: 2px 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: -6px;
    position: relative;
    width: 22px;
    height: 20px;
}
.badgeCount {
    background: #e33e3e;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50px;
    padding: 2px 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: -6px;
    position: relative;
    width: 22px;
    height: 20px;
}
.mobileBadgeCount {
    background: #e33e3e;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    border-radius: 45px;
    padding: 2px 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: -6px;
    position: relative;
    width: 20px;
    height: 18px;
}

@media (max-width: 1600px) {
    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    header .headerGridLayout .ASlogo img {
        max-width: 225px;
    }
}

@media (max-width: 1300px) {
    header .headRt ul li span.material-icons {
        font-size: 20px;
    }
}

@media (max-width: 1240px) {
    header .headLt .headerSearchForm {
        max-width: 200px;
    }

    header .headLt .headerSearchForm input {
        width: 100%;
    }

    header .headLt .headerSearchForm input::placeholder {
        font-size: 14px;
    }

    .headerGridLayout {
        display: grid;
        grid-template-columns: 35% 1fr 43%;
        padding: 12px 0;
    }
}

@media (max-width: 1145px) {
    header .headRt ul li:not(:last-child) {
        /* margin-inline-end: 5px; */
    }

    header .headRt ul li a {
        font-size: 15px;
    }

    header .headRt li > button {
        min-width: max-content;
        margin-inline-end: 5px;
        margin-inline-start: 10px;
    }
}
@media (max-width: 1130px) {
    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: #000 !important;
    }

    .aa-Panel {
        z-index: 99999 !important;
        min-width: 90%;
    }

    .respHeaderSearch .headerSearchForm input {
        background-color: #f3f3f3;
    }

    .respHeaderSearch
        .headerSearchForm
        .customSearch
        .aa-Form
        .aa-InputWrapperPrefix
        .aa-Label
        .aa-SubmitIcon {
        color: #f03e3f;
        width: 17px;
    }

    .respHeaderSearch .headerSearchForm .customSearch .aa-Form .aa-InputWrapperPrefix .aa-Label {
        margin: 0;
    }

    .respHeaderSearch
        .headerSearchForm
        .customSearch
        .aa-Form
        .aa-InputWrapperPrefix
        .aa-Label
        .aa-SubmitButton {
        width: 45px;
        height: 45px;
    }

    .respHeaderSearch .headerSearchForm .customSearch .aa-Form .aa-InputWrapper {
        border-radius: 100px;
        border: 1px solid #f3f3f3;
        order: 0;
    }

    .respHeaderSearch .headerSearchForm .customSearch .aa-Form .aa-InputWrapperPrefix {
        order: 2;
        border: 1px solid #f3f3f3;
        border-radius: 100px;
        height: 45px;
        width: 45px;
        margin-left: 8px;
        background: #f3f3f3;
    }

    .respHeaderSearch .headerSearchForm .customSearch .aa-Form .aa-InputWrapperSuffix {
        order: 1;
    }

    .respHeaderSearch .headerSearchForm .customSearch .aa-Form:focus-visible {
        box-shadow: unset;
    }

    .respHeaderSearch .headerSearchForm .customSearch .aa-Form {
        border-radius: 100px;
        border: 1px solid transparent;
        box-shadow: unset;
    }

    /* side Drawer View */
    header .headerGridLayout .ASlogo img {
        max-width: 200px;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 10px;
    }

    header .headRt ul li a {
        font-size: 15px;
    }

    .deskNav {
        display: none !important;
    }

    .respNav {
        display: block !important;
    }

    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 6px 20px;
        color: #4a4a4a;
        font-weight: 600;
        /* justify-content: space-between; */
    }

    .headRt.respNav .MuiListItem-root .languageSwitch {
        padding-inline-start: 15px;
    }

    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root .switchUserDD .customSelect {
        margin-bottom: 0 !important;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        padding-inline-start: 0px;
        color: #a6a6a6;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav a,
    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-weight: 600;
        flex-wrap: wrap;
        text-transform: capitalize;
    }

    .headRt.respNav a .hnContact,
    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .headRt.respNav a.active,
    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .headRt.respNav .naLogoHead img {
        width: 100%;
        object-fit: scale-down;
    }

    header .headLt img {
        max-width: 200px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .mobShopCurrLocation {
        padding-bottom: 0 !important;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    /* side Drawer View end */

    /* .headRt.respNav button {
        justify-content: space-between;
    } */

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }

    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }

    header {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;
        box-shadow: 0px 3px 10px #cccccc61;
    }

    .headRt.respNav .naLogoHead .MuiButtonBase-root {
        width: auto;
        min-width: fit-content;
    }

    .headRt.respNav .naLogoHead .searchIco img {
        width: 20px;
        height: 20px;
        object-fit: scale-down;
    }
}

@media (max-width: 500px) {
    header .headRt ul li span.material-icons {
        color: #000;
    }

    header.mainHeader .headRt ul li .MuiButton-root {
        margin: 0;
        margin-inline-start: 0 !important;
        margin-inline-end: 0 !important;
        padding: 6px 6px;
    }

    .headRt.respNav .naLogoHead {
        padding: 15px;
    }

    .headRt.respNav .naLogoHead img {
        max-width: 170px;
        height: auto;
    }

    .langDropMenu.rtl .MuiPopover-paper {
        top: unset !important;
    }

    .searchSlide h3,
    .searchSlide h4 {
        font-size: 16px;
    }

    .searchSlide h3 span {
        margin-inline-start: 10px;
        font-size: 13px;
    }

    .popularCatg .catgType label h4 {
        font-size: 14px;
    }

    header.mainHeader {
        height: 70px;
    }

    header.mainHeader .headLt img {
        max-width: 190px;
    }

    header .headRt ul li a {
        padding: 0 10px;
    }
}

/* ******* */
