.customDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 300px;
}

.customDialog .MuiPaper-root.MuiDialog-paper .customInput .MuiInputLabel-root,
.customDialog .MuiPaper-root.MuiDialog-paper .customSelect .MuiInputLabel-root {
    font-size: 15px;
    font-weight: 400;
    color: var(--greyPrime100);
}

.customDialog .MuiPaper-root.MuiDialog-paper .customInput .MuiInputLabel-root {
    white-space: nowrap;
}

.customDialog .MuiPaper-root.MuiDialog-paper .customInput,
.customDialog .MuiPaper-root.MuiDialog-paper .customSelect {
    margin-bottom: 15px;
    margin-top: 15px;
}

.customDialog .MuiPaper-root.MuiDialog-paper .customInput .MuiOutlinedInput-notchedOutline {
    border-color: #e1e1e1;
}

.customDialog .MuiPaper-root.MuiDialog-paper .customInput .MuiOutlinedInput-root {
    border-radius: 8px;
    font-family: var(--fontFamily);
}

.customDialog .MuiDialogContent-root {
    padding-bottom: 20px;
}

.customDialog .actionWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}

.customDialog .actionWrapper .secButton {
    margin-right: 5px;
}

.customDialog .actionWrapper > button:first-child {
    margin-inline-end: 30px;
}

.customDialog .MuiDialogTitle-root {
    padding-bottom: 10px;
}

.customDialog .actionWrapper.secondaryActions {
    justify-content: center;
}

.customDialog .MuiDialogContent-root h5 {
    font-size: 18px;
}
