.filterPanel {
    position: sticky;
    top: 15px;
}

/* GRID */
.productCardGrid {
    width: 100%;
    height: 100%;
    text-align: start;
    background: #fff;
    padding: 10px;
    margin-inline: 25px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 10px;
    max-width: 350px;
}
.pvRt .primButton button {
    font-size: 16px;
}
.search .productCardGrid {
    margin-inline: 0;
}

.productCardGrid .pcgImg {
    width: 100%;
    height: 250px;
    position: relative;
    border-radius: 10px;
}

.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 10;
}

.productCardList .pclImg .favoriteCheck label {
    color: #ff4646;
}

.productCardGrid .pcgImg .favoriteCheck label .material-icons {
    font-size: 20px;
}

.productCardGrid .pcgImg .favoriteCheck input:checked + label,
.productCardList .pclImg .favoriteCheck input:checked + label {
    color: #ff4646;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
    font-size: 14px;
    min-width: 100%;
    width: 100%;
    padding: 5px;
    font-weight: 700;
    background: var(--primColor);
    border: none;
    color: white;
}

.productCardList .productWinningStatus {
    padding: 10px 30px;
    color: #fff;
    height: 30px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
    width: auto;
    width: max-content;
    line-height: 1;
}

.productCardGrid .productWinningStatus {
    padding: 16px 15px;
    color: #fff;
    height: auto;
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    line-height: 22px;
}

.productCardGrid .productWinningStatus.lost {
    background: #575757c7;
}

.productCardGrid .productWinningStatus.outbid {
    background: rgba(83, 40, 186, 0.8);
}
.productCardGrid .productWinningStatus.outbids {
    background: rgba(240, 126, 84, 0.7);
}

.productCardGrid .productWinningStatus.won {
    background: #11a241c7;
}

.productCardGrid .productWinningStatus.prevAward {
    background: #a82626c7;
}

.productCardGrid .productWinningStatus.winning {
    background: #2596ecc7;
}

.productCardList .productWinningStatus.won {
    background: #11a241;
}

.productCardList .productWinningStatus.winning {
    background: #2596ecc7;
}

.productCardList .productWinningStatus.outbid {
    background: #ec5b25;
}

.productCardList .productWinningStatus.lost {
    background: #ec5b25;
}

.productCardGrid .reserveNotMet,
.productCardList .reserveNotMet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55px;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(114, 142, 255, 90%);
    color: #fff;
    cursor: pointer;
}

.productCardGrid .productWinningStatus:nth-child(1) {
    top: 20px;
}

.productCardGrid .pcgImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.productCardGrid .gridProdTitle {
    font-size: 18px;
    color: #231f20;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 6px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    text-transform: capitalize;
}

.productCardGrid .gridLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridLotDetails p {
    font-size: 13px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 6ex;
    text-align: center;
    width: 100%;
}

.productCardGrid .gridTimer .material-icons {
    padding-inline-end: 10px;
}

.productCardGrid .gridTimer {
    width: 100%;
    padding: 8px 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.productCardGrid .gridTimer .material-icons-outlined {
    font-size: 20px;
}

/* .productCardGrid .gridTimer h6 {
    margin: 0;
    font-size: 12px;
    padding-inline-end: 5px;
} */

.productCardGrid .gridTimer p h6.timeType {
    margin: 0;
    padding-right: 3px;
    font-size: 15px;
}

.spanish .productCardGrid .gridTimer p h6,
.spanish .productCardGrid .gridTimer p h6.timeType {
    font-size: 14px;
}

.productCardGrid .gridTimer p {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    padding-left: 6px;
    display: flex;
    align-items: center;
}

.spanish .productCardGrid .gridTimer p {
    padding-left: 3px;
}

.productCardGrid .gridTimer .grdTmrIcon {
    position: relative;
    height: 30px;
}

.productCardGrid .gridTimer .grdTmrIcon img {
    background: white;
    position: absolute;
    margin-top: -22px;
    padding: 8px;
    border-radius: 100px;
    left: 43%;
}

.productCardGrid .gridBidInfo {
    margin-top: 5px;
    height: 25px;
}

.productCardGrid .gridBidInfo h6 {
    margin-bottom: 0;
    font-size: 15px;
    color: #373737;
}

.productCardGrid .gridBidInfo p {
    margin-bottom: 0;
    color: #616161;
    font-size: 15px;
    font-weight: 600;
}

.productCardGrid .gridBidInfo p span.gridDivider {
    padding: 0 15px;
}

.productCardGrid .gridTimer p span {
    padding-inline-end: 3px;
    padding-inline-start: 3px;
}

.spanish .productCardGrid .gridTimer p span {
    padding-inline-end: 1px;
    padding-inline-start: 1px;
}

.productCardGrid .gridBidBox {
    margin-top: 15px;
    height: 7ex;
}

.productCardGrid .gridBidBox .MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiFormLabel-filled {
    width: 100%;
    word-break: break-all;
}

.productCardGrid .gridBidBox .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 13px;
    width: calc(100% - 10%);
    word-break: break-word;
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    width: 100%;
    font-size: 9px;
    margin-inline-start: 0;
}

.productCardGrid .gridBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .HelperTextGroup .secButton + .HelperTxt {
    font-size: 12px;
}

.productCardGrid .gridBidBox .biddingCnt .HelperTextGroup .secButton {
    width: 100%;
}

.productCardGrid .gridBidBox .biddingCnt .customSelect {
}

.productCardGrid .gridBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.productCardGrid .gridBidBox .biddingCnt .primButton,
.productCardGrid .gridBidBox .biddingCnt .secButton {
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .primButton:only-child,
.productCardGrid .gridBidBox .biddingCnt .secButton:only-child {
    width: 100%;
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root input {
    background: var(--accentColor);
    border-radius: 8px;
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root input::placeholder {
    font-size: 16px;
    color: #7d7d7d;
    font-weight: 400;
    font-family: var(--fontFamily);
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root {
    border: none;
}

.productCardGrid .customInput .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.productCardGridInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
}

/* .productCardGrid .gridBidBox .biddingCnt.beforeLogin .primButton {
    width: 100%;
} */

/* GRID END */

/* AUCTION CARD */

.auctionCard {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px #0000001f;
}

.auctionCard .auctionCardLt {
    width: 70%;
    padding: 10px;
}

.auctionCard .acImg {
    width: 100%;
    max-width: 250px;
    min-width: 250px;
    height: 200px;
    position: relative;
    margin-inline-end: 20px;
}

.auctionCard .acImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.auctionCard .acContent {
    margin-inline-end: 15px;
    text-align: start;
}

.auctionCard .acActBtn {
    width: 263px;
    border-left: 1px solid #ebebeb;
}

.auctionCard .acContent h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #231f20;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform: uppercase;
}

.auctionCard .acContent p {
    font-size: 15px;
    font-weight: 400;
    color: #646464;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.auctionCard .acContent .acAddress {
    height: 11ex;
}

.auctionCard .acContent .acStartDate {
    color: #333;
    font-weight: 700;
}

.auctionCard .acContent .acStartDate span {
    padding-inline-end: 10px;
    color: #333;
    width: 100px;
    display: inline-block;
}

.auctionCard .acTimer .primButton {
    width: 90%;
    margin-top: 10px;
}

.auctionCard .acTimer .primButton button {
    width: 100%;
}

/* .auctionCard .acActBtn .acTimer h6 {
    display: flex;
    align-items: center;
    color: #2e2e2e;
    font-weight: 700;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;
    text-transform: capitalize;
    padding: 13px;
    font-size: 13px;
} */

/* .auctionCard .acActBtn .acTimer h6 span {
    padding-inline-end: 10px;
} */

.auctionCard .acActBtn .acTimer p {
    color: #646464;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
}

.auctionCard .pvTimerView {
    /* display: block; */
    width: 100%;
}

.auctionCard .acTimer > h6 {
    padding: 15px;
    color: #2e2e2e;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.auctionCard .pvTimerView h6 {
    text-align: center;
    /* display: flex; */
    align-items: center;
    margin-right: 0 !important;
    /* padding-left: 25px; */
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    /* border-top: 1px solid #f2f2f2; */
}

.auctionCard .pvTimerView h6 span {
    width: 50px;
    margin: 0;
}

/* AUCTION CARD END */

/* LIST */

.productCardList {
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 5px;
}

.productCardList .psListLt {
    width: 75%;
    border-right: 1px solid #e4e4e4;
    margin-inline-end: 20px;
}

.productCardList .listLotDetails {
    margin-bottom: 20px;
}

.productCardList .listLotDetails {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 15px;
}

.productCardList .timerListView {
    font-size: 15px;
    text-align: center;
    margin-bottom: 0;
}

.productCardList .timerListView .material-icons-outlined {
    margin: 0 auto 5px;
}

.productCardList .timerListView span:last-child {
    width: 100%;
    display: block;
}

.productCardList .pclImg {
    width: 200px;
    position: relative;
    margin-inline-end: 20px;
}

.productCardList .pclImg img {
    width: inherit;
    display: block;
    height: 210px;
    object-fit: cover;
    border-radius: 3px;
}

.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 0;
    right: 0;
}

.productCardList .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 20px;
}

.productCardList .listActBtn {
    width: 25%;
}

.productCardList .listContent .listProdTitle {
    font-size: 16px;
    color: #231f20;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.productCardList .listActBox {
    width: 30%;
}

.productCardList > p {
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.productCardList .listBidInfo h6 {
    margin: 0;
    font-size: 15px;
    color: #373737;
    padding-inline-end: 25px;
    min-width: 120px;
}

.productCardList .listTimer h6 {
    width: 100%;
    font-size: 14px;
    color: #373737;
    margin-bottom: 5px;
}

.productCardList .listTimer p {
    margin: 0;
    font-size: 15px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p {
    margin: 0;
    font-size: 14px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.productCardList .listBidInfo {
    margin-top: 5px;
}

.productCardList .listTimer p span {
    padding-inline-end: 5px;
}

.productCardList .listBidBox {
    margin: 15px 0;
}

.productCardList .listBidBox .biddingCnt {
    flex-wrap: wrap;
}

.productCardList .listBidBox .biddingCnt > div {
    width: 100%;
}

.productCardList .listBidBox .customInput {
    width: 100%;
    margin-bottom: 10px;
}

.productCardList .listBidBox .primButton,
.productCardList .listBidBox .secButton {
    width: 100%;
}

.productCardList button.bidDetails {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* LIST END */

/* CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    padding-bottom: 25px;
    /* margin-bottom: 25px; */
    /* border-bottom: 1px solid #d7dadc; */
}

.cartItem .itemInfo {
    width: 75%;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 150px;
    position: relative;
    margin-inline-end: 20px;
}

.cartItem .pclImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.cartItem .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 20px;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: #0e131f;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 15px;
    margin: 0;
    line-height: 25px;
    display: flex;
}

.cartItem .listContent .listLotInfo h5:nth-child(2) {
    padding-inline-start: 20px;
    border-left: 1px solid #ccc;
    margin-inline-start: 20px;
}

.cartItem .listContent .listLotInfo span:first-child {
    width: auto;
    min-width: 90px;
    display: inline-block;
    max-width: 150px;
}

.cartItem .listContent .listLotInfo span:last-child {
    color: var(--secColor);
    font-size: 14px;
    font-weight: 700;
    padding-inline-start: 5px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-inline-end: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

.cartItem .updateCartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
}

.cartItem .updateCartQuantity .customInput .MuiFormControl-root .MuiOutlinedInput-root {
    width: 120px;
    padding: 5px 35px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border-radius: 50px;
    text-align: center;
    height: 40px;
}

.cartItem .updateCartQuantity input:focus {
    outline: 0;
}

.cartItem .updateCartQuantity input {
    padding: 0;
    text-align: center;
}

.cartItem .updateCartQuantity button {
    min-width: max-content;
    position: absolute;
    top: 3px;
    z-index: 10;
    color: var(--primColor);
}

.cartItem .updateCartQuantity button:first-child {
    left: 10px;
    border-radius: 50px;
}

.cartItem .updateCartQuantity button.Mui-disabled {
    color: #ccc;
}

.cartItem .updateCartQuantity button:last-child {
    right: 10px;
    border-radius: 50px;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-inline-end: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: start;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 30px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 100%;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: start;
    white-space: nowrap;
}

.customModal .bidHistoryModal .modal-dialog.modal-lg {
    max-width: 550px;
}

.productCardGrid .moreInfo button,
.productCardList .moreInfo button {
    color: var(--textColor);
    text-transform: initial;
}

.productCardGrid .moreInfo button .material-icons,
.productCardList .moreInfo button .material-icons {
    padding-inline-start: 5px;
}

/*DOWNLOAD CARD*/
.gridCard {
    width: 100%;
    box-shadow: 0 0 10px #00000026;
    background: white;
    height: 320px;
}

.gridCard .pcgImg img {
    width: 100%;
    height: 240px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 96%);
    object-fit: cover;
    object-position: top;
}

.gridCard .pcgImg {
    width: 100%;
    height: 240px;
    position: relative;
}

.gridCard .pdtDtls {
    padding: 10px 20px;
}

.gridCard .pdtDtls .pdtName {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: var(--displayFont);
}

.gridCard .pdtDtls .cstDtls div:first-child {
    border-right: 1px solid #c1c1c1;
}

.gridCard .pdtDtls .cstDtls div p {
    font-weight: bold;
    font-size: 1.125rem;
}

.gridCard .pdtDtls .cstDtls .bdCnt {
    font-size: 0.8rem;
    color: rgb(59, 152, 228);
    text-decoration: underline;
}

.gridCard .pdtDtls {
    position: relative;
}

.dnldGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.proTimer {
    position: absolute;
    top: auto;
    bottom: 0;
    background: #00000066;
    left: 0;
    right: 0;
    border-radius: 10px;
    margin: 5px auto;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

/* 22.05.23 */

.productView
    .productViewContent
    .pvRt
    .gridCrdProDetails
    .gridCrdProBdy
    .gridCrdProUnlst
    .gridCrdProLst
    object,
.productView
    .productViewContent
    .pvRt
    .gridCrdProDetails
    .gridCrdProBdy
    .gridCrdProUnlst
    .gridCrdProLst
    span,
.productView
    .productViewContent
    .pvRt
    .gridCrdProDetails
    .gridCrdProBdy
    .gridCrdProUnlst
    .gridCrdProLst
    img,
.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst img,
.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst object,
.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst span {
    padding-right: 6px;
    font-size: 22px;
}

.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst .pTagrange span {
    color: var(--primColor);
    font-weight: bolder;
    font-size: 20px;
    line-height: 0;
    margin: 0;
    padding: 0 5px;
}

.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst:nth-child(3) {
    /* grid-column: 1/3; */
    /* justify-content: flex-end; */
}

.productView
    .productViewContent
    .pvRt
    .gridCrdProDetails
    .gridCrdProBdy
    .gridCrdProUnlst
    .gridCrdProLst,
.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
}

.productView
    .productViewContent
    .pvRt
    .gridCrdProDetails
    .gridCrdProBdy
    .gridCrdProUnlst
    .gridCrdProLst
    .legalCondi {
    width: 35px;
    margin-right: 10px;
    height: 35px;
    object-fit: contain;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.productView .productViewContent .pvRt .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst,
.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
    display: grid;
    padding-left: 0;
    color: var(--secColor);
    list-style-type: unset;
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 17px;
    margin-bottom: 5px;
    margin-top: 5px;
    gap: 10px;
}

.productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
    /* grid-template-columns: repeat(auto-fit, minmax(25px, auto)); */
    grid-template-columns: repeat(4, auto);
    gap: 10px;
}

.productCardGrid .watchOut span {
    color: #727272;
    font-size: 20px;
    font-weight: 500;
}
.productCardGrid .watchOut {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 10;
    cursor: pointer;
}

.productView .productViewContent .pvRt .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
    margin-bottom: 35px;
}

.productView .productViewContent .pvRt .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
    grid-template-columns: auto auto auto;
    gap: 12px;
}

.productCardGrid .gridCrdProDetails.homeGrid .gridCrdProUnlst {
    min-height: 35px;
}

.searchResults .productCardGrid .curntBid {
    font-size: 16px;
    font-weight: 700;
    color: var(--primColor);
    line-height: 23px;
    margin-right: 15px !important;
}
.productCardGrid .curntBid {
    font-size: 16px;
    font-weight: 700;
    color: var(--primColor);
    line-height: 23px;
    margin-right: 30px;
}
@media (max-width: 1560px) {
    .searchResults .productCardGrid .curntBid {
        margin-right: 12px !important;
    }
    .productCardGrid .curntBid {
        margin-right: 25px;
    }
}

.productCardGrid .contaminationLevel img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.productCardGrid .contaminationLevel {
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    z-index: 1;
}
.productCardGrid .cardTopLabel {
    position: absolute;
    top: 5px;
    left: 5px;
    background: #6666668f;
    width: calc(100% - 10px);
    border-radius: 4px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productCardGrid .ProductStatus {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productCardGrid .cardTopLabel .category {
    margin: 0;
    color: #fff;
    font-size: 13px;
    text-align: left;
}

.productCardGrid .cardTopLabel .favoriteCheck {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 10;
}

.productCardGrid .cardTopLabel .favoriteCheck label {
    color: #727272;
    margin-top: 8px;
}

/* responsive css */
@media (max-width: 1500px) {
    .productCardGrid .curntBid {
        margin-right: 20px;
    }
    .searchResults .productCardGrid .curntBid {
        margin-right: 25px !important;
    }
}
@media (max-width: 1440px) {
    .productView
        .productViewContent
        .pvRt
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst
        .gridCrdProLst,
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst {
        font-size: 14px;
    }
    .productCardGrid .curntBid {
        margin-right: 26px;
    }
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        font-size: 13px;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst span {
        font-size: 16px;
        padding-right: 3px;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst .gridCrdProLst {
        padding-right: 0;
    }
    .productCardGrid .curntBid {
        font-size: 16px;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
        gap: 3px;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        grid-template-columns: repeat(auto-fit, minmax(25px, auto));
        /* grid-template-columns: repeat(4, auto); */
        gap: 2px;
    }
    .spanish .productCardGrid .gridTimer p span,
    .spanish .productCardGrid .gridTimer p h6.timeType {
        font-size: 14px;
    }
}

@media (min-width: 1370px) {
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        gap: 5px;
    }
}

@media (max-width: 1360px) {
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
        /* grid-template-columns: repeat(auto-fill, minmax(48%, 1fr)); */
    }
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        font-size: 12px;
    }
    .productCardGrid .curntBid {
        margin-right: 21px;
    }
}

@media (max-width: 1280px) {
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
        gap: 5px;
    }
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        font-size: 11px;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        grid-template-columns: 1fr 1fr;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
        grid-template-columns: 1fr 1fr;
    }
    .productCardGrid .gridBidBox .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        max-width: 125px;
    }
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst
        .gridCrdProLst:nth-child(3) {
        justify-self: start !important;
    }
}

@media (max-width: 1100px) {
    .productCardGrid .gridTimer p {
        flex-direction: column;
        align-items: flex-start;
    }

    .productCardGrid .customInput .MuiFormControl-root.MuiTextField-root input::placeholder {
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    .productCardList {
        flex-wrap: wrap;
    }
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        font-size: 10px;
    }
}

@media (max-width: 768px) {
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst,
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        grid-template-columns: auto auto;
    }
    .cartItem {
        flex-wrap: wrap;
        width: 48%;
    }

    .cartItem .itemInfo {
        width: 100%;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none;
        margin-inline-end: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }

    .cartItem .listContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .cartItem .listActBtn {
        width: 100%;
    }
}

@media (max-width: 645px) {
    .productCardGrid {
        max-width: 100%;
        margin: auto;
    }

    .Landinglayout .productCardGroup .homeAuctiontypes .auctionTitHder {
        padding-right: 0;
    }

    .productCardGrid .gridBidBox,
    .productCardGrid .gridBidInfo,
    .productCardGrid .gridLotDetails p {
        height: auto;
    }

    .gridViewSkeleton {
        max-width: none;
    }
}

@media (max-width: 600px) {
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst
        .gridCrdProLst:nth-child(3) {
        justify-content: flex-start;
    }

    .productCardGrid .curntBid {
        margin-right: -37px;
    }
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        font-size: 16px;
    }
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst,
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        grid-template-columns: 1fr 1fr;
    }
    .productCardGrid .gridCrdProDetails.homeGrid .gridCrdProUnlst {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 500px) {
    .productView .productViewContent .pvRt .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst {
        gap: 10px;
        margin-bottom: 15px;
    }
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 52px;
    }
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst
        .curntBid {
        margin: 0 !important;
    }

    .productCardGrid .gridTimer p {
        flex-direction: row;
        align-items: flex-start;
    }

    .Landinglayout .productCardGroup .homeAuctiontypes .auctionTitHder .auctionTitle h3,
    .Landinglayout .productCardGroup .homeAuctiontypes .auctionTitHder .auctionTitle h4 {
        font-size: 18px;
    }

    .Landinglayout .productCardGroup .homeAuctiontypes .auctionTitHder {
        padding-right: 30px;
    }

    .auctionCard {
        flex-wrap: wrap;
    }

    .auctionCard .auctionCardLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .auctionCard .acImg {
        max-width: initial;
        margin-inline-end: 0;
    }

    .auctionCard .acContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .auctionCard .acActBtn {
        width: 100%;
    }

    .auctionCard .acTimer {
        /* flex-wrap: nowrap !important; */
        /* justify-content: flex-start !important; */
        margin: 10px 0;
    }

    /* .auctionCard .acActBtn .acTimer h6 {
        justify-content: flex-start;
        width: max-content;
    } */
    .auctionCard .pvTimerView {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }

    .auctionCard .pvTimerView h6 {
        padding-left: 0;
        font-size: 16px;
    }

    .auctionCard .pvTimerView h6 span {
        width: auto;
        padding-right: 10px;
    }

    .auctionCard .acActBtn .acTimer p {
        margin: 0;
        margin-inline-start: 10px;
    }

    .auctionCard .acContent h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .auctionCard .acContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }

    .cartItem .itemInfo {
        width: 100%;
    }

    .productCardGrid .gridProdTitle {
        font-size: 16px;
    }

    /* .productCardGrid .gridTimer h6, */
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }

    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }

    /* .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    } */
    /* .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        width: 49%;
    } */

    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }

    .similarItem .pclImg {
        width: 30%;
        margin-inline-end: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }

    .cartItem .listContent .listLotInfo h5 {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 480px) {
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst,
    .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        grid-template-columns: repeat(4, auto);
    }
    .searchResults
        .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 30px;
    }
    .productCardGrid .gridCrdProDetails.homeGrid .gridCrdProUnlst {
        grid-template-columns: auto auto;
    }
    .searchResults .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst,
    .searchResults .productCardGrid .gridCrdProDetails .gridCrdProBdy .gridCrdProUnlst.fstRow {
        grid-template-columns: repeat(3, auto);
    }
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst
        .gridCrdProLst:nth-child(3) {
        justify-content: flex-end;
    }

    .productCardGrid .gridCrdProDetails.homeGrid .gridCrdProUnlst {
        grid-template-columns: auto auto auto;
    }
}

@media (max-width: 450px) {
    .auctionCard .acContent h2 {
        font-size: 14px;
    }
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 32px;
    }
    .searchResults
        .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 45px;
    }

    .auctionCard .acContent p {
        font-size: 12px;
    }

    .auctionCard .acContent {
        margin-bottom: 10px;
    }
}

/* @media (min-width: 1440px) {
  .productCardGrid {
    width: 24%;
  }
} */
@media (max-width: 400px) {
    .searchResults
        .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 35px;
    }
}
@media (max-width: 360px) {
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        font-size: 14px;
    }
    .searchResults
        .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 30px;
    }
    .productCardGrid
        .gridCrdProDetails
        .gridCrdProBdy
        .gridCrdProUnlst.SecRow
        .justify-self-end.gridCrdProLst {
        width: 100%;
        justify-content: end;
        padding-right: 28px;
    }
}
