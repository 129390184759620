.registration .plBox .regType label {
    width: 180px;
    height: 180px;
    border: 1px dashed var(--secColor);
    border-radius: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: 200ms all ease-in-out;
}

.regForm {
    margin-bottom: 15px;
}

.regForm .S9gUrf-YoZ4jf iframe {
    margin-left: auto !important;
    margin-right: auto !important;
}

.registration .plBox .regType label img {
    filter: contrast(0.1);
    transition: 300ms all linear;
}

.registration .plBox .regType:first-child {
    margin-inline-end: 50px;
}

.registration .plBox .regType input:checked ~ label {
    background: #fff2e7;
    border-color: var(--primColor);
    box-shadow: 0px 3px 50px #4caf5014;
}

.registration .plBox .regType input:checked ~ label img {
    filter: grayscale(1) brightness(0.4);
}

.registration .regSelection {
    margin-top: 45px;
}

.registration .plBox .regType input:checked ~ label:after {
    content: '\f058';
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: var(--primColor);
}

.registration .plBox .regType h5 {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    transition: 200ms all ease-in-out;
}

.registration .plBox .regType input:checked ~ h5 {
    color: var(--primColor);
}

.registration .plBox .primButton {
    width: max-content;
    min-width: 150px;
    margin: 20px auto;
}

.formRadio {
    margin-bottom: 15px;
}

.formRadio p {
    padding-inline-end: 35px;
    margin: 0;
}

.formRadio label {
    margin-bottom: 0;
}

.registration .regActBox {
    width: max-content;
    margin: 20px auto;
}

.registration .plBox .regActBox .primButton {
    margin: 5px auto;
    width: 250px;
}

.registration .plBox Help {
    font-size: 16px;
    color: #373737;
    margin-bottom: 5px;
    margin-top: 15px;
}

.registration .plBox Help a {
    color: var(--primColor);
    display: block;
}

.registration .plBox .regActBox .MuiListItem-root {
    padding-inline-start: 0;
}

.registration .plBox .regActBox .MuiListItem-root a {
    padding-inline-start: 5px;
}

.registration .MuiStepIcon-root.MuiStepIcon-completed,
.registration .MuiStepIcon-root.MuiStepIcon-active {
    color: var(--primColor);
}

.registration .MuiStepper-root {
    padding: 20px 0 40px 0;
}

.registration .crActBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.registration .crActBtn .primButton,
.registration .crActBtn .secButton {
    min-width: 150px;
    margin: 0;
    margin-inline-end: 15px;
}

.registration .crActBtn .secButton button {
    color: var(--textColor);
    border-color: currentColor;
}

.registration .crActBtn .secButton button:hover {
    background: var(--textColor);
    color: #fff;
}

.registration .plBox .RadioBox .radioTitle {
    /* display: inline-block; */
    display: none;
    padding-inline-end: 20px;
}

.registration .plBox .RadioBox .MuiFormGroup-root {
    display: inline-block;
}

.registration .plBox .MuiFormControlLabel-root {
    margin: auto;
}

.crActBtn .secButton {
    margin-inline-end: 15px;
}

.addMore span {
    padding-inline-end: 10px;
}

.addMore {
    float: right;
}

.addMore .MuiButton-label {
    color: #757575;
}

.removeCurrent.MuiButtonBase-root.MuiButton-root {
    float: right;
    min-width: max-content;
    margin: 15px 0 5px 0;
    color: #e56868;
    display: none;
}

.regUpdImage label {
    border: 1px dashed #c9c5d7;
    padding: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #737373;
    flex-wrap: wrap;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

.regUpdImage label h5 {
    width: 100%;
    font-size: 14px !important;
    font-weight: 700;
    margin-bottom: 8px !important;
    margin-top: 8px;
}

.regUpdImage label p {
    width: 100%;
    font-size: 14px;
    margin: 0;
}

.regTnc label {
    margin: 20px auto;
}

.registration .catgItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.registration .catgItems .checkboxHeading {
    text-align: left;
}

.registration .catgItems .customCheckbox {
    width: 20%;
}

.registration .plBox .catgItems .MuiFormControlLabel-root {
    margin: 0;
}

.registration .plBox .regSubtitle {
    text-align: start;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #3a3a3a;
}

/* 18/05/23 */

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .ModalLogoSec
    .Logo {
    margin-bottom: 56px;
}

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .ModalLogoSec
    .Tit {
    margin-bottom: 18px;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    color: var(--primColor);
    text-transform: uppercase;
}

.customDialog.registerDialogVIew .MuiDialogContent-root .registrationCnt .registration {
    box-shadow: unset;
    padding: 0;
    background: #fff;
    border-radius: 0;
}

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .regContainer
    .regActBox,
.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .regContainer
    .regActBox
    .primButton {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 0;
}

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .regContainer {
    padding: 0;
}

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .ModalLogoSec
    .subTit {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--darkGreyPrime);
}

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .ModalLogoSec {
    display: block;
    text-align: center;
    padding-bottom: 30px;
}

.customDialog.registerDialogVIew .MuiDialogContent-root .registrationCnt {
    background: #ffffff;
}

.customDialog.registerDialogVIew .MuiDialogContent-root {
    padding: 0 30px;
    padding-bottom: 30px;
}

.customDialog.registerDialogVIew
    .MuiDialogContent-root
    .registrationCnt
    .registration
    .regContainer
    .rgTypeWrpr
    label {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
}

.googleSignUp {
    padding: 15px 0;
}

/* responsive css */

@media (max-width: 600px) {
    .customDialog.registerDialogVIew
        .MuiDialogContent-root
        .registrationCnt
        .registration
        .ModalLogoSec {
        padding-bottom: 15px;
    }

    .customDialog.registerDialogVIew .MuiDialogContent-root {
        padding: 0 20px;
    }

    .customDialog.registerDialogVIew
        .MuiDialogContent-root
        .registrationCnt
        .registration
        .regContainer
        .rgTypeWrpr {
        flex-wrap: wrap;
    }

    .customDialog.registerDialogVIew
        .MuiDialogContent-root
        .registrationCnt
        .registration
        .regContainer
        .rgTypeWrpr
        label {
        width: 100%;
        text-align: center;
        margin: auto !important;
    }

    .regContainer .rgTypeWrpr .tabBtns {
        margin-right: 0;
    }

    .customDialog.registerDialogVIew
        .MuiDialogContent-root
        .registrationCnt
        .registration
        .ModalLogoSec
        .Tit {
        margin-bottom: 15px;
        font-size: 20px;
    }

    .customDialog.registerDialogVIew
        .MuiDialogContent-root
        .registrationCnt
        .registration
        .ModalLogoSec
        .Logo {
        margin-bottom: 56px;
        width: 100%;
        object-fit: contain;
        max-width: 230px;
    }
}

@media (max-width: 500px) {
    .registration .catgItems .customCheckbox {
        width: 100%;
    }

    .registration .plBox h2 {
        font-size: 24px;
    }

    .registration .plBox h4 {
        font-size: 16px;
    }

    .registration .brandLogo {
        width: 150px;
    }

    .registration .plBox .regType:first-child {
        margin-inline-end: 10px;
    }

    .registration .plBox .regType label {
        width: 150px;
        height: 150px;
    }

    .registration .regSelection {
        margin-top: 30px;
    }

    .registration .formRadio p {
        padding-inline-end: 0;
        text-align: start;
        width: 100%;
        font-size: 15px;
        text-align: start;
    }

    .registration .regActBox {
        width: 100%;
    }

    .registration .plBox .regActBox .MuiListItem-root {
        flex-wrap: wrap;
    }

    .registration .plBox .regActBox .MuiListItem-root a {
        padding-inline-start: 0px;
    }

    .registration .plBox .regActBox {
        margin: 0 auto;
    }

    .registration .plBox .regActBox .primButton {
        width: 100%;
    }

    .registration .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 10px;
        font-size: 12px;
    }
}

@media (max-width: 445px) {
    .registration .plBox .regType label img {
        width: 50px;
    }
}

@media (max-width: 380px) {
    .registration .plBox h2 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .registration .plBox .regType label {
        width: 130px;
        height: 130px;
    }
}

.login.regContainer {
    width: 100%;
    max-width: 800px;
    border: 0;
    padding: 0;
    margin: auto;
}

.regContainer {
    background: #fff;
    padding: 0 45px 45px;
}

.regContainer .RadioBox {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
    justify-content: center;
}

.regContainer .RadioBox .radioTitle {
    margin-bottom: 0;
    margin-right: 20px;
}

.regContainer .regActBox {
    margin-top: 40px;
}

.regContainer .regActBox .primButton {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.regContainer .rgTypeWrpr .tabBtns {
    padding: 10px;
    margin-right: 10px;
    color: #000;
}

.regContainer .rgTypeWrpr .tabBtns .MuiButton-label .material-icons {
    color: var(--primColor);
}

.regContainer .rgTypeWrpr .tabBtns .MuiButton-label {
    text-transform: none;
    font-weight: 400;
}

.regContainer .rgTypeWrpr .tabBtns .MuiButton-label .material-icons {
    padding-right: 8px;
}

.regContainer .rgTypeWrpr .tabBtns.active {
    background: #f7f8fe;
    color: var(--primColor);
}

.regContainer .rgTypeWrpr .tabBtns.active .MuiButton-label {
    font-weight: 500;
}

@media (max-width: 600px) {
    .regContainer {
        padding: 0 26px 45px;
    }
}

@media (max-width: 500px) {
    .regContainer .RadioBox {
        flex-direction: column;
        align-items: self-start;
        justify-content: flex-start;
    }

    .registration .plBox .RadioBox .radioTitle {
        margin-bottom: 10px;
    }

    .registration .customCheckbox .MuiButtonBase-root {
        font-size: 14px;
    }
}
