.dashboard {
    background: #f6f6f6;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: calc(100vh - 90px);
}

.dashboard .customBreadcrumbs {
    margin-bottom: 10px;
}

.alertDialog.customDialog .cdTop.lisitnModal {
    padding: 0;
}

.deleteAccountCol {
    display: flex;
    align-content: stretch;
    align-items: stretch;
    height: 88%;
}

.dashboard.listing,
.dashboard .dashboardInner {
    background: #fff;
    padding: 35px 30px;
    margin-top: 5px;
}

.dashboard .dashboardInner .searchMiscFilters {
    justify-content: space-between !important;
}

.dashboard .dashboardInner .searchMiscFilters .pageTit {
    display: none;
}

.dashboardInner.mySavedCards .saved-card {
    position: relative;
}

.dashboardInner.mySavedCards .saved-card .sc-btns-wrap {
    position: absolute;
    top: 0;
    right: 0;
}

.dashboardInner.mySavedCards .saved-card .sc-btns-wrap .MuiIconButton-root {
    color: #fff;
}

.dashboardRt .dashboardInner .MuiPaper-root tr th {
    white-space: nowrap;
}

.dashboard .dashboardRt .maTitle {
    padding: 15px 30px;
    background: #fff;
}

.dashboardInner.mySavedCards .updatefrm,
.dashboardInner.mySavedCards .cardDetails {
    grid-column: 1 / 3;
}

.sideNav .userAvatarProfile {
    display: none;
    background: #fff;
    padding: 35px 0 20px 0px;
    border-bottom: 1px solid #f1f3f6;
}

.sideNav ul {
    background: #fff;
    /* padding: 20px 0 10px 20px; */
    padding-left: 0;
}

.sideNav .userAvatarProfile .material-icons {
    font-size: 100px;
    color: #c7c7c7;
}

.sideNav .userAvatarProfile img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
}

.sideNav .userAvatarProfile h4 {
    font-size: 18px;
    color: #373737;
    margin: 15px auto;
    word-break: break-all;
    padding: 0 10px;
}

.sideNav hr {
    margin-bottom: 25px;
    background-color: #f1f3f6;
}

.sideNav h5 {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    text-align: start;
    padding-top: 15px;
}

.sideNav ul {
    /* padding-inline-start: 0; */
    margin: 0;
}

.profileTermCheck {
    border-top: 1px solid #c9c9c9;
    padding-top: 25px;
}

.sideNav ul a,
.sideNav ul .MuiListItem-button {
    width: 100%;
    height: 100%;
    display: block;
    color: #646464 !important;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.sideNav ul .MuiListItem-button {
    height: 60px;
    padding: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.sideNav ul .MuiCollapse-wrapperInner .MuiList-root {
    margin: 12px 0;
}

.sideNav ul .MuiCollapse-wrapperInner .MuiListItem-button {
    height: fit-content;
}

.sideNav ul a,
.sideNav ul .test {
    padding: 15px 25px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 100%;
}

.sideNav ul a span,
.sideNav ul .MuiListItem-button span {
    padding-inline-end: 10px;
}

.sideNav ul .MuiCollapse-wrapperInner {
    padding-inline-start: 20px;
}

.sideNav ul a:hover {
    text-decoration: none;
}

.sideNav ul > .MuiListItem-button a.active {
    color: var(--primColor) !important;
    background: #fff;
}

.sideNav ul .activeDropdown {
    background: var(--primColor) !important;
    color: #fff;
}

.sideNav ul .MuiListItem-button a.active {
    color: var(--primColor) !important;
}

.sideNav ul .MuiListItem-button:nth-last-child(1) a {
    /* margin-bottom: 24px; */
}

.sideNav ul .MuiListItem-button a {
    padding: 15px 25px;
}

.dashboard .dashboardLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.dashboard .dashboardLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.dashboard .dashboardLt .gridListToggle i {
    padding-inline-end: 10px;
}

.dashTitle {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.dashTitle span {
    font-size: 12px;
    font-weight: 600;
    color: #999999;
    padding-inline-start: 0px;
    text-transform: initial;
    display: block;
}

.dashTitle .fs {
    font-size: 28px;
    font-weight: 400;
}

.myAccount .accountCnt {
    background: #fff;
    border-radius: 5px;
    width: 100%;
}

.myAccount .accChild h4 {
    text-align: start;
    font-size: 18px;
    font-weight: 400;
    margin: 25px 0;
    margin-top: 0;
    color: var(--darkGrey);
}

.myAccount .primButton,
.notifications .primButton {
    max-width: 115px;
    margin: 20px auto;
}

.myAccount .accountCnt .crActBtn .primButton {
    margin: 0 0 15px 0;
    min-width: 150px;
}

.myAccount .accountCnt .crActBtn .secButton {
    min-width: 150px;
}

.myAccount .userProfileImage {
    position: relative;
}

.myAccount .userProfileImage .noUserImage {
    font-size: 160px;
    color: #c7c7c7;
}

.myAccount .userProfileImage .editUserImage {
    width: 30px;
    height: 30px;
    min-width: auto;
    border-radius: 50%;
    background: var(--primColor);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25px;
    right: 20px;
    font-size: 18px;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(25, 157, 229, 40%);
}

.mySavedCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
}

.mySavedCards .emptySavedCard {
    border: 1px dashed#A6ADB4;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
}

.listingInfo.singlList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.mySavedCards .savedAddressCard h4 span {
    padding-bottom: 8px;
    font-weight: 700;
    display: block;
}

.mySavedCards .savedAddressCard h4 {
    font-size: 16px;
    text-align: left;
}

.mySavedCards .savedAddressCard {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 12px;
}

.mySavedCards .emptySavedCard h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #4d4d4d;
    font-weight: 600;
}

.mySavedCards .filledSavedCard {
    height: 100%;
    min-height: 175px;
    border-radius: 5px;
    background: url('../../assets/svg/cardBg.svg'),
        linear-gradient(#5a5a5a 0%, #323335 49.33%, #000 100%);
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 1rem 0.75rem;
    color: #fff;
}

.pageTit .Tit {
    font-size: 33px;
    font-weight: 700;
    color: var(--darkGreyPrime);
}

.search .searchRt .searchMiscFilters .customSelect {
    min-width: fit-content;
    line-height: 0;
}

.search .searchRt .searchMiscFilters .customSelect fieldset {
    border: unset;
}

.search .searchRt .searchMiscFilters .customSelect .MuiSelect-icon {
    color: #000;
}

.search .searchRt .searchMiscFilters .customSelect .MuiSelect-outlined.MuiSelect-outlined {
    padding: 0;
    padding-right: 35px;
    font-size: 15px;
    font-weight: 400;
    color: #000 !important;
    width: 100%;
    max-width: 110px;
}

.mySavedCards .modifyCard {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.mySavedCards .modifyCard .material-icons {
    font-size: 18px;
}

.mySavedCards .modifyCard.edit {
    background: #5d80ff9c;
    color: #fff;
}

.mySavedCards .modifyCard.delete {
    background: #ff5d5d9c;
    color: #fff;
    margin-inline-start: 10px;
}

.mySavedCards .card-num {
    padding: 26px 0 15px;
    text-align: center;
}

.mySavedCards .card-num span {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1;
    margin: 5px 0;
}

.mySavedCards .card-num span + span {
    margin-inline-start: 15px;
}

.mySavedCards .fs-12 {
    font-size: 0.75rem;
    text-align: start;
}

.mySavedCards .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
}

.auctionStatus {
    display: none;
}

.auctionStatus .MuiListItem-root {
    padding: 0;
    width: max-content;
    margin-bottom: 25px;
    border-radius: 50px;
    min-width: max-content;
}

.auctionStatus .MuiListItem-root a {
    padding: 10px 25px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    color: #000;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
}

.auctionStatus .MuiListItem-root a:focus,
.auctionStatus .MuiListItem-root a:hover {
    text-decoration: none;
}

.auctionStatus .MuiListItem-root:not(:last-child) {
    margin-inline-end: 16px;
}

.auctionStatus .MuiListItem-root a.active {
    color: var(--primColor);
    background: rgb(250 236 236);
    border-color: var(--primColor);
}

.companyProfile .MuiPaper-root.MuiAppBar-root {
    color: #333;
    box-shadow: 0px 2px 2px #ccc;
    background: #f7f7f7;
    margin-top: 20px;
}

.companyProfile .MuiPaper-root.MuiAppBar-root .MuiTab-root.Mui-selected {
    color: var(--primColor);
    background: #199de517;
}

.companyProfile .MuiPaper-root.MuiAppBar-root .MuiTabs-indicator {
    background: var(--primColor);
}

.companyProfile .MuiBox-root {
    padding-top: 30px;
}

.companyProfile .catgDet > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.companyProfile .catgDet .customCheckbox {
    width: 25%;
}

.updatefrm {
}

.customCheckbox .MuiFormHelperText-root {
    text-align: left;
}

.dashboard .walletHead {
    margin-bottom: 30px;
}

.dashboard .walletBalance {
    background: #fff;
    border: 1px dashed var(--primColor);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--primColor);
    text-align: start;
    margin-inline-end: auto;
    width: max-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard .walletBalance .material-icons {
    padding-inline-end: 5px;
}

.dashboard .walletBalance span:last-child {
    padding-inline-start: 10px;
}

.dashboard h5 {
    font-size: 18px;
    text-align: start;
    margin-bottom: 20px;
    color: var(--secColor);
}

.dashboard .notifications .dashSubtitle,
.dashboard .mySavedSearches .dashSubtitle {
    font-size: 18px;
    font-weight: 400;
    color: #212529;
    line-height: 24px;
    margin-bottom: 2rem;
    text-align: left;
}

.dashboard .mySavedSearches table thead tr th:last-child {
    text-align: center;
}

.dashboard .mySavedSearches table .ssActBtn button:first-child {
    color: #003594;
}

.dashboard .mySavedSearches table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.accountCnt.companyProfile .regSubtitle {
    text-align: start;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #3a3a3a;
}

.pform-btns-wrap .primButton .MuiButton-root {
    min-width: 150px;
}

.pform-btns-wrap .primButton {
    margin-right: 10px;
}

.pform-btns-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* fulll popup */

.modalChildren .addUserModal .fspBody .listingInfo.AucManag h6 {
    margin: auto;
}

.modalChildren .addUserModal .fspBody .listingInfo.AucManag span:nth-child(1) + h6 {
    margin-left: 15px;
}

.modalChildren .addUserModal .actionButton .primButton,
.modalChildren .addUserModal .actionButton .secButton {
    min-width: 180px;
}

.modalChildren .addUserModal .fspBody .listingInfo.AucManag {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-bottom: 50px !important;
}

.actionButton .primButton,
.actionButton .secButton {
    margin: 0 5px;
}

.subsptWrapper .plnInfoList {
    list-style: none;
    padding-left: 0;
}

.subsptWrapper .plnInfoList li {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
    color: gray;
}

.subsptWrapper .plnInfoList li label {
    width: 150px;
    font-weight: 500;
    margin: 0;
}

.subsptWrapper .plnInfoList .plnName {
    color: var(--secColor);
    font-weight: 500;
}

.subsptWrapper .plnInfoList .changePlan {
    color: var(--darkFont);
    font-size: 12px;
    margin: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.subsptWrapper .plnInfoList .changePlan span.material-icons-outlined {
    font-size: 19px;
    margin-right: 6px;
}

.subsptWrapper .plnInfoList .plnStus {
    display: flex;
    align-items: center;
}

.subsptWrapper .plnInfoList .plnStus .material-icons {
    font-size: 10px;
    margin-left: 6px;
    color: green;
}

.subsptWrapper .plnInfoList .plnStus.inactive .material-icons {
    font-size: 10px;
    margin-left: 6px;
    color: rgb(235, 41, 41);
}

.subsptWrapper .acrdBtn {
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.subsptWrapper .acrdBtn .material-icons {
    margin-left: 10px;
}

.subsptWrapper .plnDtls {
    text-align: left;
}

.subsptWrapper .nxtPyInfo {
    color: gray;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.subsptWrapper .nxtPyInfo .material-icons {
    font-size: 18px;
    margin-right: 7px;
}

.subsptWrapper .seeSubPlan {
    color: var(--darkFont);
    font-size: 14px;
    text-decoration: underline;
}

.serchfilterList h4 {
    margin: auto;
    color: #737373;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.serchfilterList {
    display: flex;
    align-items: center;
}

.savedschBtn {
    background: #f50a0a14;
    padding: 5px;
    color: #f50a0a;
}

/* .productFullscreenModal figure.table table {
    border: 1px solid #000;
} */

.customDialog.validationErrorDialog .modalHeader .tit,
.customDialog.prflAlrtPopup .modalHeader .tit {
    color: red;
}

.customDialog.validationErrorDialog .modalHeader,
.customDialog.prflAlrtPopup .modalHeader {
    padding-top: 15px;
    word-wrap: break-word;
    padding-bottom: 10px;
}

.customDialog.validationErrorDialog .cdTop.lisitnModal,
.customDialog.prflAlrtPopup .cdTop.lisitnModal {
    position: absolute;
    right: 0;
    top: 10px;
}

.productFullscreenModal .RadioBox {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* responsive */

@media (max-width: 1024px) {
    .respSort {
        display: none;
    }

    .auctionStatus {
        display: flex;
    }

    /* .searchMiscFilters {
        display: none !important;
    } */
}

@media (max-width: 767px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 15px;
    }

    .dashboard .myWallet .dpTable .table,
    .dashboard .mySavedSearches .dpTable .table {
        table-layout: auto;
    }

    .dashboard .myWallet .dpTable .table td,
    .dashboard .mySavedSearches .dpTable .table td {
        min-width: 130px;
        font-size: 14px;
    }

    .dashboard .mySavedSearches .dpTable .table td:first-child {
        min-width: 60px;
    }

    .dashboard .myWallet .dpTable .table th,
    .dashboard .mySavedSearches .dpTable .table th {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .auctionStatus .MuiListItem-root {
        margin-bottom: 10px;
    }

    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .dashboard {
        min-height: calc(75vh - 83px);
        padding-top: 15px;
    }

    .mySavedCards {
        grid-template-columns: 1fr;
    }

    .dashboard .dashboardInner .searchMiscFilters.inr .serchfilterList {
        width: 100%;
        justify-content: space-between;
        padding-right: 0 !important;
        padding-bottom: 10px;
    }

    .dashboard .dashboardInner .searchMiscFilters,
    .dashboard .dashboardInner .searchMiscFilters {
        flex-direction: column;
        margin-bottom: 0.5rem !important;
    }

    .search .searchRt .searchMiscFilters .customSelect .MuiSelect-outlined.MuiSelect-outlined {
        text-align: left;
        max-width: 100%;
    }

    .auctionStatus .MuiListItem-root a {
        font-size: 14px;
        height: 40px;
    }

    .actionButton .primButton,
    .actionButton .secButton {
        margin: 0 3px;
    }

    .modalChildren .addUserModal .fspBody .listingInfo.AucManag h6 {
        margin: auto 0;
    }

    .listingInfo.singlList,
    .modalChildren .addUserModal .fspBody .listingInfo.AucManag {
        flex-wrap: wrap;
        align-items: flex-start !important;
    }

    .modalChildren .addUserModal .actionButton .primButton button,
    .modalChildren .addUserModal .actionButton .secButton button {
        padding: 5px 10px;
    }

    .modalChildren .addUserModal .actionButton .primButton,
    .modalChildren .addUserModal .actionButton .secButton {
        min-width: 86px;
    }

    .modalChildren {
        padding: 25px 15px !important;
    }

    .modalChildren .productFullscreenModal .fspBody .actionButton {
        gap: 15px;
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }

    .dashboard.listing,
    .dashboard .dashboardInner {
        padding: 20px 15px;
        margin-top: 5px;
    }
}

@media (max-width: 545px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        height: 40px;
        font-size: 15px;
        line-height: 1.2;
    }

    .dashTitle {
        font-size: 15px;
    }

    .dashboard .dashboardRt .maTitle {
        padding: 5px 15px;
    }

    .dashboard .myAuctions,
    .dashboard .myInvoices,
    .dashboard .mySavedCards,
    .dashboard .myAccount,
    .dashboard .myWallet,
    .dashboard .notifications,
    .dashboard .mySavedSearches,
    .dashboard .dashboardInner,
    .dashboard.listing {
        padding: 12px 12px;
    }

    .dashboard .miscActions {
        margin-top: 1rem !important;
    }

    .myAccount .userProfileImage .noUserImage {
        font-size: 120px;
    }

    .myAccount .accChild h4 {
        font-size: 16px;
    }

    .myAccount .primButton,
    .notifications .primButton {
        max-width: 100%;
        margin: 10px auto 20px;
    }

    .dashboard .walletBalance {
        width: 100%;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 10px;
    }

    .dashboard .walletBalance span:last-child {
        margin-left: auto;
    }

    .auctionStatus {
        margin-bottom: 10px;
        overflow: auto;
        flex-wrap: wrap;
    }
}
.customDialog .cdTop .closeBtn {
    width: 45px;
    min-width: 45px;
    height: 5px;
    min-width: initial;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 15px !important;
}

@media (max-width: 500px) {
    .dashboard .dashboardInner .searchMiscFilters.inr .serchfilterList {
        flex-wrap: wrap;
    }

    .dashboard .dashboardInner .searchMiscFilters.inr .serchfilterList:first-child {
        padding-bottom: 12px !important;
    }

    .serchfilterList h4 {
        width: 100%;
        text-align: left;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-left: 0;
        width: 100%;
    }

    .dashboard .dashboardRt .maillisting .miscActions button {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .dashboard .dashboardRt .maillisting .miscActions {
        justify-content: center !important;
    }

    .auctionStatus {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .auctionStatus .MuiListItem-root {
        width: 100%;
        margin: 0 !important;
    }

    .auctionStatus .MuiListItem-root > a {
        height: 35px;
        width: 95%;
    }

    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .dashboard .notifications .dashSubtitle,
    .dashboard .mySavedSearches .dashSubtitle {
        font-size: 14px;
    }

    .subsptWrapper .d-flex h4 {
        margin-bottom: 15px !important;
    }

    .subsptWrapper .d-flex {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .subsptWrapper .d-flex.tmSwtBox {
        flex-direction: row;
        align-items: center !important;
        justify-content: center !important;
    }

    .subsptWrapper .plnInfoList li label {
        width: 100%;
    }

    .subsptWrapper .plnInfoList .plnName {
        margin-right: 10px !important;
    }

    .subsptWrapper .plnInfoList .changePlan {
        margin-left: 0;
    }

    .subsptWrapper .plnInfoList li {
        flex-wrap: wrap;
    }
}

@media (max-width: 470px) {
    .mySavedCards .emptySavedCard img[alt='cardtype'] {
        width: 100%;
    }

    .auctionStatus .MuiListItem-root a {
        padding: 10px 15px;
        height: 40px;
        font-size: 13px;
        text-align: center;
        line-height: 1.4;
    }
}

@media (max-width: 420px) {
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .auctionStatus .MuiListItem-root {
        margin-bottom: 5px;
    }

    .auctionStatus {
        margin-bottom: 10px;
    }
}

@media (max-width: 385px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        font-size: 13px;
        text-align: center;
    }

    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }

    .auctionStatus {
        flex-wrap: wrap;
    }
}

@media (max-width: 340px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 12px;
    }

    .auctionStatus .MuiListItem-root {
        margin-bottom: 15px;
    }
}
