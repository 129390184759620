.registrationCnt {
    background: #eef0f4;
}

.registration {
    border-radius: 10px;
    padding: 45px;
    box-shadow: 0px 3px 30px rgb(43 43 43 / 10%);
}

.registration .plBox {
    margin: 0 auto;
}

.registration .regInfo {
    background: #fff;
    padding: 45px 35px 30px;
}

.registration .plBox h2 {
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
}

.registration .plBox h4 {
    font-weight: 600;
}

.registration .brandLogo {
    width: 200px;
    margin-bottom: 30px;
}

@media (max-width: 1024px) {
    .registration {
        border-top: 1px solid #e9e9e9;
        box-shadow: none;
    }
}

@media (max-width: 500px) {
    .registration {
        border: none;
        margin: 30px auto 0;
        padding: 30px;
    }
}

@media (max-width: 445px) {
    .registration {
        padding: 15px 0;
    }
}
