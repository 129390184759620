body {
    margin: 0;
    font-family: var(--fontFamily), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dashboardInner.myAccount .RadioBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    grid-row-gap: 12px;
    row-gap: 12px;
}

.initalLoadingCnt {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 60px !important;
    padding-inline-end: 60px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 30px;
    /* width: 100%; */
}

.productFullscreenModal .datePickerLabel ~ .ck {
    margin-bottom: 20px;
}

.dashboardInner .moreBtnPRT,
.productFullscreenModal .moreBtnPRT {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 45px;
}

.productFullscreenModal .moreBtnPRT .addMore {
    width: 100%;
    margin: 0 15px;
    background: #ffffff;
    padding: 15px 35px;
    border: 1px dashed #6a6a6a;
}

.productFullscreenModal .fspBody .checkboxError .MuiFormHelperText-root {
    text-align: left;
}

.productFullscreenModal .fspBody .checkboxError {
    margin-bottom: 15px;
}

.productFullscreenModal .moreBtnPRT .MuiButton-label {
    color: #6a6a6a;
}

.productFullscreenModal.customContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.ck-editor-spacing .ck.ck-editor__editable_inline p {
    margin-bottom: 0px !important;
}
.ck-editor-spacing .ck.ck-editor__editable_inline p:last-child {
    margin-bottom: 10px !important;
}

.productFullscreenModal .RadioBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 12px;
}

.productFullscreenModal .RadioBox .radioTitle {
    margin-bottom: 0;
}

.productFullscreenModal .RadioBox .MuiFormControlLabel-root {
    margin-bottom: 0;
    align-items: flex-start;
}

.productFullscreenModal .RadioBox .MuiIconButton-root {
    padding-top: 0;
    padding-bottom: 0;
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.MuiTablePagination-input,
.MuiTablePagination-input .MuiSelect-nativeInput {
    font-family: var(--fontFamily) !important;
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
    /* overflow: hidden; */
}

.MuiInputBase-input {
    color: var(--greyPrime100) !important;
}

.MuiFormLabel-root {
    font-family: var(--fontFamily) !important;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt .deskFilter .pageTit {
    text-align: center;
    margin-bottom: 30px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
    margin-top: 0px;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 305px);
    overflow: hidden;
}

.search .searchRt {
    max-width: calc(100% - 0px);
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 13px;
}

.search .searchResults.Grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .customSelect .MuiFormControl-root.MuiTextField-root {
    margin-top: 0px;
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-inline-end: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.dpTable table {
    color: #212529;
}

.dpTable table thead th {
    background: #343a40;
    color: #fff;
}

.dpTable table .MuiFormControlLabel-root {
    margin: 0;
}

.dpTable table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
    padding-left: 25px;
}

.searchSkeletonBody {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.userInitials.small {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: #3d3d3d;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInitials.large {
    width: 90px;
    height: 90px;
    font-size: 28px;
    font-weight: 700;
    background: #f1f3f6;
    border-radius: 50%;
    color: #9ca8b9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.regDivider {
    margin-bottom: 35px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 50vmax;
    max-width: 800px;
    padding: 20px;
}

.pvTimerView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pvTimerView h6 {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
}

.pvTimerView h6:not(:last-child) {
    margin-right: 0px;
}

.pvTimerView h6 span {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    padding-right: 0;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.savedBankCnt {
    display: grid;
    margin-top: 25px;
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.savedBankCnt .savedBank {
    padding: 15px;
    text-align: left;
    background: #ebebeb;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin: 0;

    background-image: linear-gradient(to right, #b0fbff 0%, #4facfe 100%);
}

.savedBankCnt .savedBank .material-icons {
    font-size: 45px;
    color: var(--primColor);
}

.savedBankCnt .savedBank h4 {
    font-size: 16px;
    margin: 20px 0 15px;
    font-weight: 600;
    color: #333;
}

.savedBankCnt .savedBank h5 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
    color: #333;
    letter-spacing: 4px;
}

.savedBankCnt .savedBank.addNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px dashed #ddd;
    cursor: pointer;
}

.savedBankCnt .savedBank.addNew .material-icons {
    margin-bottom: 20px;
    color: #abaeb3;
}

.savedBankCnt .savedBank.addNew h6 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #275168;
}

.savedBankCnt .savedBank h4 span,
.savedBankCnt .savedBank h5 span {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: var(--primColor);
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.savedBankCnt .savedBank .edtBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
}

.savedBankCnt .savedBank .dltBtn .MuiButton-label {
    font-size: 15px;
    font-weight: 600;
    color: #dc3545;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar {
    max-width: 1600px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar .MuiTypography-root {
    margin-left: 0;
}

.MuiAccordionDetails-root .MuiSlider-root {
    color: var(--primColor);
}

.productFullscreenModal .listingHeading {
    padding: 10px 15px;
    background: #f4f4f4;
    margin: 25px 0;
    font-size: 20px;
}

.productFullscreenModal .tireTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productFullscreenModal .tireTitle img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-left: 12px;
}
.listing.maillisting
    .MuiTable-root
    .MuiTableHead-root
    .MuiTableCell-root
    .MuiButtonBase-root.MuiTableSortLabel-root {
    max-width: 100% !important;
    white-space: nowrap;
}
.listing.maillisting .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
    max-width: 100% !important;
    white-space: nowrap;
}

.changePassBtn a {
    text-align: center;
}
@media (min-width: 1270px) and (max-width: 1358px) {
    .th-text {
        max-width: 160px;
    }
}
@media (min-width: 1236px) and (max-width: 1269px) {
    .th-text {
        max-width: 160px;
    }
}

@media (max-width: 1600px) {
    .search .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

@media (max-width: 1440px) {
    .search .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    }
    .pvTimerView h6,
    .productCardGrid .gridTimer p h6.timeType {
        font-size: 14px;
    }
}

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

@media (max-width: 1024px) {
    .search .searchRt .pageTit .Tit {
        font-size: 22px;
        text-align: left;
        padding-bottom: 15px;
    }

    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff2e7;
        border-color: rgb(25 157 229 / 20%) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* @media (max-width: 991px) {
    .customContainer {
        padding-inline-start: 30px !important;
        padding-inline-end: 30px !important;
    }
} */

.MuiDivider-root {
    /* background-color: rgb(149 208 242 / 55%) !important; */
}

.dashboardInner.myAccount .accChild {
    text-align: left;
}

.dashboardInner.myAccount .accChild .dropzone {
    height: 56px;
    padding: 10px;
}

.dashboardInner.myAccount .accChild .dropzone h4 {
    margin-bottom: 0 !important;
}

@media (max-width: 1350px) {
    /* .listing.maillisting
        .MuiTable-root
        .MuiTableHead-root
        .MuiTableCell-root
        .MuiButtonBase-root.MuiTableSortLabel-root {
        word-break: break-all;
    }
    .listing.maillisting .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
        max-width: 100% !important;
        white-space: wrap;
    } */
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }

    .bigWordCls a {
        width: 84% !important;
        text-align: center !important;
    }

    .bigWordTitle {
        text-align: center !important;
        display: flex;
        align-items: center;
        margin-left: -10px;
        margin-right: 16px;
    }

    .auctionMyStatus {
        overflow-x: hidden !important;
    }
}

@media (max-width: 600px) {
    .search .pagination-wrapper {
        justify-content: center !important;
    }
    .productFullscreenModal.customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .dashboardInner.myAccount .accountCnt .profileAct {
        display: block;
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .search .productCardGrid,
    .search .productCardGridInner {
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px 0px;
        max-width: 100%;
        box-shadow: unset;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }

    .pagination-wrapper h6 {
        font-size: 14px;
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}

.header-title {
    width: 100px;
}

.make-offer-notification {
    color: var(--primColor);
}
@media (max-width: 514px) {
    .header-align {
        text-align: center;
    }
}
