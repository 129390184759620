:root {
    --fontFamily: 'Red Rose';
    --primColor: #f03e3f;
    --secColor: #7b7b7b;
    --accentColor: #f3f3f3;
    --backgroundColor: #ffffff;
    --textColor: #000000;
    --darkGrey: #333333;
    --darkGreyPrime: #4d4c4c;
    --greyPrime: #676767;
    --greyPrime100: #7d7d7d;
    --bluePrime: #2348a7;
    --organgePrime: #f96621;
}

.MuiPaper-root .MuiTypography-root,
.MuiFormHelperText-root,
.MuiFormControl-root,
.MuiFormLabel-root.MuiInputLabel-root,
.MuiButtonBase-root,
.MuiTableRow-root .MuiTableCell-root {
    font-family: var(--fontFamily) !important;
    letter-spacing: initial !important;
}

.primButton button {
    color: #fff;
    border-radius: 8px;
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
    line-height: initial;
}

.primButton button:hover {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: #fff;
    opacity: '0.9';
    box-shadow: none;
}

.secButton button {
    color: var(--backgroundColor);
    border-radius: 8px;
    background-color: var(--textColor);
    border: 1px solid var(--textColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
    line-height: initial;
}

.secButton button:hover {
    background-color: var(--backgroundColor);
    border: 1px solid var(--textColor);
    color: var(--textColor);
    box-shadow: none;
}

.primButton button,
.secButton button,
.tertButton button {
    height: 50px;
}

.primButton.small button,
.secButton.small button,
.tertButton.small button {
    height: 40px;
    min-width: max-content;
}

.justify-self-end {
    justify-self: end;
}

.primButton.large button,
.secButton.large button,
.tertButton.large button {
    height: 60px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

::selection {
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

@media (max-width: 500px) {
    .primButton.small button,
    .secButton.small button,
    .tertButton.small button {
        height: 40px;
        font-size: 13px;
    }

    .primButton button,
    .secButton button,
    .tertButton button {
        height: 45px;
        font-size: 14px;
    }

    .primButton.large button,
    .secButton.large button,
    .tertButton.large button {
        height: 50px;
        font-size: 14px;
    }
    .home .homeBanner .hbLt .bannerSearch input {
        height: 50px;
    }
}
