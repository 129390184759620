.loginCnt {
    background: #eef0f4;
    padding: 60px 0;
}

.login {
    max-width: 550px;
    background: #fff;
    margin: 30px auto;
    border-radius: 5px;
    padding: 45px;
}

.login .brandLogo {
    margin-bottom: 35px;
    width: 200px;
}

.login h1 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    text-transform: uppercase;
}

.login h2 {
    font-size: 18px;
    margin-bottom: 35px;
}

.login .loginActBox .MuiButtonBase-root.MuiListItem-root {
    padding-inline-start: 0;
}

.login .loginActBox .primButton {
    width: 160px;
}

.login .MuiDivider-root {
    margin: 25px 0;
}

.login .primButton {
    width: 200px;
    margin-left: auto;
}

.login.forgotPassword .primButton {
    width: 100%;
    max-width: fit-content;
    margin-right: auto;
}

/* .login.resetPassword h2{
  margin-bottom: 35px;
} */

.login .loginMiscAction a {
    color: #333;
}

/* 18/15/23 */

.modalView.loginVIew .MuiDialog-paperWidthSm {
    max-width: 536px;
}

.modalView.loginVIew .ModalLogoSec .Logo {
    margin-bottom: 56px;
    max-width: 320px;
    width: 100%;
}

.modalView.loginVIew .ModalLogoSec .Tit {
    margin-bottom: 18px;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    color: var(--primColor);
    text-transform: uppercase;
}

.customDialog .MuiDialogContent-root .forPass .aPass,
.customDialog.registerDialogVIew .MuiDialogContent-root .forPass .aPass,
.customDialog.modalView.loginVIew .MuiDialogContent-root .forPass .aPass {
    color: var(--primColor);
}

.spanish
    .customDialog.modalView.loginVIew
    .MuiDialogContent-root
    .customCheckbox
    .MuiButtonBase-root {
    padding-left: 5px;
}

/* .spanish .customDialog.modalView.loginVIew .MuiDialogContent-root {
    padding-left: 25px;
    padding-right: 25px;
} */

.customDialog.registerDialogVIew .MuiDialogContent-root .forPass,
.customDialog.modalView.loginVIew .MuiDialogContent-root .forPass {
    padding-top: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}

.customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe {
    padding-top: 0;
    padding-bottom: 25px;
}

.customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe .MuiListItem-button {
    padding-left: 0;
    padding-right: 0;
}

.customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe .MuiButton-root {
    text-align: right;
}

.spanish
    .customDialog.modalView.loginVIew
    .MuiDialogContent-root
    .remebrMe
    .MuiButton-root
    .MuiButton-label {
    text-transform: initial;
    font-size: 16px;
    font-weight: 400;
}

.customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe .MuiButton-label {
    font-size: 15px;
    text-align: right;
    text-transform: capitalize;
}

.customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe .MuiFormControlLabel-root {
    margin: 0;
}

.spanish
    .customDialog.modalView.loginVIew
    .MuiDialogContent-root
    .remebrMe
    .customCheckbox
    p.MuiFormHelperText-root {
    margin: 0;
}

.spanish
    .customDialog.modalView.loginVIew
    .MuiDialogContent-root
    .remebrMe
    .customCheckbox
    .MuiButtonBase-root {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 15px;
}

.customDialog.modalView.loginVIew .MuiDialogContent-root {
    padding-left: 30px;
    padding-right: 30px;
}

.modalView.loginVIew .ModalLogoSec .subTit {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--darkGreyPrime);
}

.modalView.loginVIew .ModalLogoSec {
    display: block;
    text-align: center;
    padding-bottom: 50px;
}

/* responsive */

@media (max-width: 600px) {
    .modalView.loginVIew .ModalLogoSec {
        padding-bottom: 25px;
    }
}

@media (max-width: 500px) {
    .login.forgotPassword .primButton button {
        height: fit-content;
        font-size: 12px;
    }

    .spanish .customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe {
        flex-direction: column;
    }

    .modalView.loginVIew .ModalLogoSec .Logo {
        margin-bottom: 40px;
        max-width: 300px;
    }

    .customDialog.modalView.loginVIew .MuiDialogContent-root {
        padding-left: 20px;
        padding-right: 20px;
    }

    .login {
        border: none;
        margin: 30px auto;
        padding: 30px;
    }

    .login h1 {
        font-size: 24px;
    }

    .login h1 {
        font-size: 16px;
    }

    .login .brandLogo {
        width: 150px;
    }

    .login .loginActBox {
        flex-wrap: wrap;
    }

    .login .loginActBox .primButton {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .login {
        padding: 15px;
    }

    .login .loginMiscAction a {
        font-size: 13px;
    }

    .customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe {
        flex-wrap: wrap;
    }
}

@media (max-width: 380px) {
    .spanish .customDialog.modalView.loginVIew .MuiDialogContent-root .remebrMe {
        flex-direction: column;
    }

    .login h1 {
        font-size: 22px;
        margin-bottom: 5px;
    }
}
